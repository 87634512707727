import React, { useState } from 'react';

const allOptions = () => {
  return (
    <>
      <option value="">All</option>
      <option value="pending">Apt. Pending</option>
      <option value="unattempted">Unattempted</option>
      <option value="paymentPending">Payment Pending</option>
      <option value="appointmentConfirmed">Confirmed</option>
      <option value="appointmentConfirmedByUser">Confirmed by User</option>
      <option value="prescriptionPending">Rx Pending</option>
      <option value="prescriptionUploaded">Rx Uploaded</option>
      <option value="reconfirmationAwaited">Reconfirmation Awaited</option>
      <option value="prescriptionRejected">Rx Rejected</option>
      <option value="prescriptionApproved">Rx Approved</option>
      <option value="unclaimed">Unclaimed</option>
      <option value="userCanceled">User Canceled</option>
      <option value="noShow">No Show</option>
      <option value="pac">PAC</option>
      <option value="canceled">Cancelled</option>
      <option value="rescheduled_call_user">Call User</option>
      <option value="rescheduled_app">Appt. Rescheduled</option>
      <option value="rescheduled_escalated">User Escalated</option>
      <option value="rescheduled_confirm_escalated">
        Confirmed and Escalated
      </option>
      <option value="rescheduled_cancel_escalated">
        Cancelled and Escalated
      </option>
    </>
  );
};

const pendingOptions = () => {
  return (
    <>
      <option value="">All Pending</option>
      <option value="pending">Apt. Pending</option>
      <option value="paymentPending">Payment Pending</option>
      <option value="noShow">No Show</option>
      <option value="pac">PAC</option>
      <option value="rescheduled_call_user">Call User</option>
      <option value="rescheduled_app">Appt. Rescheduled</option>
    </>
  );
};

const StatusFilter = ({ value, segregation, onChangeValue }) => {
  const changeFilterType = (event) => {
    const value = event.target.value === '' ? null : event.target.value;
    console.log({ value }, 'Filter Inner Component');
    onChangeValue(value);
  };

  const getOptions = (seg) => {
    console.log(
      { seg, pendingOptions: pendingOptions() },
      'Getting options for Header Status Filter'
    );
    if (seg === 0) {
      return pendingOptions();
    }
    return allOptions();
  };

  console.log('segregation', segregation);
  return (
    <select
      onChange={changeFilterType}
      style={{
        width: '100%',
        fontSize: '12px',
        borderRadius: '10px',
        height: '42px',
      }}
      value={value}
    >
      {getOptions(segregation)}
    </select>
  );
};

export default StatusFilter;
