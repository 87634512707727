import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button, Input } from 'reactstrap';
import { Select } from 'antd';
import { useState} from 'react';
import React from 'react';
import { markRequestAsStuck } from '../../services/api/offline-consult';

const reasonOptions = [
  { label: 'User is unresponsive to 3 call attempts', value: 'User is unresponsive to 3 call attempts' },
  { label: 'Center is unresponsive to 3 call attempts', value: 'Center is unresponsive to 3 call attempts' },
  { label: 'User preferred slot is unavailable and no alternatives found', value: 'User preferred slot is unavailable and no alternatives found' },
  { label: 'Center is denying appointment due to tie-up/pending payment issue', value: 'Center is denying appointment due to tie-up/pending payment issue' },
  { label: 'Center is temporarily/permanently closed, and no alternatives found', value: 'Center is temporarily/permanently closed, and no alternatives found' },
  { label: 'Doctor is temporarily/permanently unavailable, and no alternatives found', value: 'Doctor is temporarily/permanently unavailable, and no alternatives found' },
];

const StuckRequestModal = ({ isOpen, onClose, requestId }) => {
  const [selectedReason, setSelectedReason] = useState(null);
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState(''); // State for remarks

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleReasonChange = (value) => {
    setSelectedReason(value);
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      // Call the API here, replace the URL with your actual API endpoint
      const response = await markRequestAsStuck(selectedReason, requestId, remarks)
      console.log(response);
      if(response.message == 'success'){
        onClose(); 
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle error, show error message to the user
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal centered isOpen={isOpen} toggle={onClose}>
      <ModalHeader className="revamp-modal-header" toggle={onClose}>
        Stuck Request ({requestId})
      </ModalHeader>
      <ModalBody>
        Are you sure you want to mark this request as stuck?

        <Row style={{ padding: '10px' }}>
          <Col style={{ fontSize: '16px', fontWeight: '500' }}>
            Reason:{' '}
          </Col>
          <Col>
            <Select
              style={{ width: '450px' }}
              placeholder="Select a reason"
              options={reasonOptions}
              onChange={handleReasonChange}
              value={selectedReason}
            />
          </Col>
        </Row>
        <Row style={{ padding: '10px' }}>
          <Col style={{ fontSize: '16px', fontWeight: '500' }}>
            Remarks:{' '}
          </Col>
          <Col>
            <Input
              type="text"
              style={{ width: '450px' }}
              value={remarks}
              onChange={handleRemarksChange}
              placeholder="Enter any remarks"
            />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          style={{
            backgroundColor: 'rgb(113, 79, 255)',
            width: '40%',
            borderColor: 'rgb(113, 79, 255)',
            height: '44px',
          }}
          onClick={() => onSubmit()}
          disabled={loading || !selectedReason || !remarks}
        >
          {loading ? 'Loading...' : 'Confirm'}
        </Button>
        {!loading && (
          <Button
            style={{
              backgroundColor: 'rgb(255, 255, 255)',
              width: '40%',
              borderColor: 'rgb(113, 79, 255)',
              color: 'rgb(113, 79, 255)',
              height: '44px',
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default StuckRequestModal;
