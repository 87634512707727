import baseRequest from './baseRequest';
import { store } from '../../store';
import config from '../../config';
import axios from 'axios';

const baseUrl = config.baseUrl;

export const getAllOfflineRequests = (params) => {
  console.log({ params }, 'Params');
  const url = '/new-ops/offline-consult/all';
  const method = 'GET';
  return baseRequest(url, method, params, null).then(
    (response) => response.data
  );
};

export const getAllOfflineRequestsCount = (params) => {
  const url = '/new-ops/offline-consult/all/count';
  const method = 'GET';
  return baseRequest(url, method, params, null).then(
    (response) => response.data
  );
};

export const getDoctorSelectionList = (params) => {
  const url = '/new-ops/requests/getSearchDoctors';
  const method = 'GET';
  return baseRequest(url, method, params, null);
};

export const appointDoctorRequest = (body) => {
  const url = '/new-ops/requests/doctor-update';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const claimRequest = (requestId) => {
  if (!requestId) {
    throw new Error('No Request Id Found');
  }
  const url = `/new-ops/offline-consult/request/${requestId}/claim`;
  const method = 'POST';
  return baseRequest(url, method, null, null);
};

export const getDoctorSlots = (params) => {
  const url = `/new-ops/doctors/slots`;
  const method = 'GET';
  return baseRequest(url, method, params, null);
};

export const pacOrNoShow = (body, requestId) => {
  const url = `/new-ops/requests/${requestId}/update-status`;
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const requestReconfirmationForOfflineConsult = (requestId) => {
  const url = `/new-ops/requests/${requestId}/reconfirmation-status`;
  const method = 'PUT';
  return baseRequest(url, method, null, null);
};

export const sendPriceChangeRequestAPI = (body) => {
  const url = `/new-ops/offline-consult/price-update-request`;
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const getOfflineRequest = (requestId) => {
  const url = `/new-ops/requests/${requestId}/details`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const getCenterInfoOfflineRequest = (requestId) => {
  const url = `/new-ops/requests/${requestId}/details/center-info`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const getPaymentInfoOfflineRequest = (requestId) => {
  const url = `/new-ops/requests/${requestId}/details/payment-info`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const getActionsInfoOfflineRequest = (requestId) => {
  const url = `/new-ops/requests/${requestId}/details/actions-info`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const updateCashlessLetterStatus = (requestId, params) => {
  const url = `/new-ops/requests/${requestId}/cashless-letter-sent`;
  const method = 'PUT';
  return baseRequest(url, method, params, null);
};

export const getPayoutStatus = (requestId) => {
  const url = `/new-ops/requests/${requestId}/refresh-payout-status`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const confirmRequest = (
  requestId,
  dateKey,
  slot,
  centerRequest,
  slotType,
  slotTimeRange
) => {
  const url = `/new-ops/consult/request/${requestId}/confirm`;
  const method = 'POST';
  const data = {
    dateKey,
    slot,
    centerRequest,
    slotType,
    slotTimeRange,
  };
  return baseRequest(url, method, null, data);
};

export const confirmDoctorAppointmentRequest = (
  requestId,
  dateKey,
  slot,
  centerRequest
) => {
  const url = `/new-ops/requests/confirm-doctor-appointment`;
  const method = 'POST';
  const data = {
    requestId,
    dateKey,
    slot,
    centerRequest,
  };
  return baseRequest(url, method, null, data);
};

export const convertConsultToReimbursement = (requestId, reason) => {
  const url = `/claims-support/convert-offline`;
  const method = 'POST';
  const data = {
    requestId,
    reason,
  };
  return baseRequest(url, method, null, data);
};

export const updateConsultationReimbursementRequest = (
  reimbursementRequestsId,
  status,
  reason
) => {
  const url = `/claims-support/v2/consultation-requests`;
  const method = 'POST';
  const data = {
    reimbursementRequestsId,
    status,
    reason,
  };
  console.log({ data });
  return baseRequest(url, method, null, data);
};

export const confirmAlternateRequest = (requestId, dateKey, slotId) => {
  const url = `/new-ops/consult/request/${requestId}/confirmAlternate`;
  const method = 'POST';
  const data = {
    dateKey,
    slotId,
  };
  return baseRequest(url, method, null, data);
};

export const cancelRequest = (
  requestId,
  reason,
  remarks,
  cancellationFee,
  subCancelReason,
  userComms
) => {
  const url = `/new-ops/consult/request/${requestId}/cancel`;
  const method = 'POST';
  const data = {
    cancelReason: reason,
    cancellationFee: cancellationFee ? 1 : 0,
    remarks,
    subCancelReason,
    userComms,
  };
  return baseRequest(url, method, null, data);
};

export const providerClaimsRequest = (requestId, reason,remarks, file, providerClaimsType) => {
  const headers={};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  const formData = new FormData();
  formData.append('file', file);
  formData.append('requestId', requestId);
  formData.append('reason', reason);
  formData.append('remarks', remarks);
  formData.append('providerClaimsType', providerClaimsType)

  return axios
    .post(
      `${baseUrl}/new-ops/consult/request/${requestId}/provider-claims-settlemet`,
      formData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp;
      if (responseBody.data.message) {
        return responseBody.data;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const searchDoctors = (search, verticalId, userId) => {
  const url = '/new-ops/searchDoctor';
  const method = 'GET';
  const query = {
    search,
    verticalId,
    userId
  };
  return baseRequest(url, method, query, null);
};

export const fetchUsersRelative = (userId) => {
  const url = `/new-ops/users/${userId}/relatives`;
  const method = 'GET';
  const data = {
    userId,
  };
  return baseRequest(url, method, null, data);
};

export const fetchUsersRelativeByphone = (phone) => {
  const url = `/new-ops/users/relatives-by-phone`;
  const method = 'GET';
  const params = {
    phone,
  };
  return baseRequest(url, method, params, null);
};

export const placeRequest = (
  doctorId,
  centerId,
  userId,
  relativeId,
  dateKey,
  slotId,
  policyNumber,
  dependant
) => {
  const url = '/new-ops/consult/offline/request';
  const method = 'POST';
  const data = {
    doctorId,
    centerId,
    userId,
    relativeId,
    dateKey,
    slotId,
    policyNumber,
    dependant,
  };
  return baseRequest(url, method, null, data);
};

export const callCustomRequest = (
  requestId,
  phoneNumber,
  userType,
  callReason
) => {
  const url = `/new-ops/offline-consult/request/${requestId}/call`;
  const method = 'POST';
  const data = {
    phoneNumber,
    userType,
    callReason,
  };
  return baseRequest(url, method, null, data);
};

export const callPatientRequest = (requestId, regarding, callReason) => {
  const url = `/new-ops/offline-consult/request/${requestId}/call/patient`;
  const method = 'POST';
  const data = {
    regarding,
    callReason,
  };
  return baseRequest(url, method, null, data);
};

export const callUserRequest = (requestId, regarding, callReason) => {
  const url = `/new-ops/offline-consult/request/${requestId}/call/user`;
  const method = 'POST';
  const data = {
    regarding,
    callReason,
  };
  return baseRequest(url, method, null, data);
};

export const callDoctorRequest = (requestId, regarding) => {
  const url = `/new-ops/offline-consult/request/${requestId}/call/doctor`;
  const method = 'POST';
  const data = {
    regarding,
  };
  return baseRequest(url, method, null, data);
};

export const getVerticals = () => {
  const url = '/new-ops/verticals/all';
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const getSponsors = () => {
  const url = '/new-ops/sponsors';
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const changeProcessingDate = (body) => {
  const url = '/new-ops/change-process-date';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const paymentRequest = (requestId) => {
  const url = `/new-ops/offline-consult/request/${requestId}/payment/request`;
  const method = 'POST';
  return baseRequest(url, method, null, null);
};

export const fetchRequestHistory = (requestId) => {
  const url = `/new-ops/offline-consult/request/${requestId}/history`;
  const method = 'GET';
  const params = {
    requestId,
  };
  return baseRequest(url, method, params, null);
};

export const waiveOffCancellationFees = (requestId) => {
  const url = `/new-ops/offline-consult/request/${requestId}/waive-cancellation-fees`;
  const method = 'POST';
  const data = {
    requestId,
  };
  return baseRequest(url, method, null, data);
};

export const resendCashlessLetter = (requestId) => {
  const url = `/new-ops/payment/approve/resendCashlessLetter/${requestId}`;
  const method = 'POST';
  const data = {
    requestId,
  };
  return baseRequest(url, method, null, data);
};

export const resendReconfirmationCommsToSpocs = (requestId) => {
  const url = `/new-ops/payment/approve/resendReconfirmationCommsToSpocs/${requestId}`;
  const method = 'POST';
  const data = {
    requestId,
  };
  return baseRequest(url, method, null, data);
};

export const resendReconfirmationCommsToUser = (requestId) => {
  const url = `/new-ops/payment/approve/resendReconfirmationCommsToUser/${requestId}`;
  const method = 'POST';
  const data = {
    requestId,
  };
  return baseRequest(url, method, null, data);
};

export const requestPayment = (requestId) => {
  const url = `/new-ops/requests/${requestId}/request-payment`;
  const method = 'POST';
  const data = {
    requestId,
  };
  return baseRequest(url, method, null, data);
};

export const addDataForNetworkCenter = (data) => {
  const url = `/new-ops/requests/add-centers-data`;
  const method = 'POST';
  return baseRequest(url, method, null, data);
};

export const addAssigneData = (data) => {
  const url = `/new-ops/requests/add-assigne`;
  const method = 'POST';
  return baseRequest(url, method, null, data);
};

export const uploadMouDocument = (data) => {
  const url = `/new-ops/requests/mou/documents`;
  const method = 'POST';
  return baseRequest(url, method, null, data);
};

export const fetchCortisData = () => {
  const url = `/new-ops/requests/fetchBillingEntities`;
  const method = 'GET';
  return baseRequest(url, method, null);
};

export const changeInvoiceMandatory = (invoiceMandatory, requestId) => {
  const url = `/new-ops/requests/${requestId}/change-invoice-mandatory`;
  const method = 'POST';
  const data = {
    invoiceMandatory,
  };
  return baseRequest(url, method, null, data);
};

export const shiftAppointment = (
  requestId,
  doctorId,
  centerId,
  reason,
  remarks,
  dateKey,
  slotId,
  slotType,
  timeRange,
  externalSlotInfo
) => {
  const url = `/new-ops/requests/${requestId}/shift`;
  const method = 'POST';
  const data = {
    requestId,
    doctorId,
    centerId,
    reason,
    remarks,
    dateKey,
    slotId,
    slotType,
    timeRange,
    externalSlotInfo
  };
  return baseRequest(url, method, null, data);
};

export const markFollowUp = (
  requestId,
  followUpRequiredFrom,
  followUpDate,
  followUpTime
) => {
  const url = `/new-ops/requests/${requestId}/followUp`;
  const method = 'POST';
  const data = {
    requestId,
    followUpRequiredFrom,
    followUpDate,
    followUpTime,
  };
  return baseRequest(url, method, null, data);
};

export const fetchFollowUp = (requestId) => {
  const url = `/new-ops/requests/${requestId}/fetch-follow-up`;
  const method = 'GET';
  const data = {
    requestId,
  };
  return baseRequest(url, method, null, data);
};

export const markFollowUpCompleted = (requestId, followUpId) => {
  const url = `/new-ops/requests/${requestId}/fetch-follow-up`;
  const method = 'PUT';
  const data = {
    followUpId,
  };
  return baseRequest(url, method, null, data);
};

export const tpaCancelTpaRequest = (requestId) => {
  const url = `/new-ops/offline-consult/send-cancellation-tpa`;
  const method = 'POST';
  const data = {
    requestId,
  };
  return baseRequest(url, method, null, data);
};

export const refreshPayoutRequest = (requestId) => {
  const url = `/new-ops/offline-consult/${requestId}/refresh-payout`;
  const method = 'PUT';

  return baseRequest(url, method, null, null);
};

export const getPricingForRequest = (requestId) => {
  const url = '/new-ops/offline-consult/pricing-info';
  const method = 'GET';
  const params = {
    requestId,
  };
  return baseRequest(url, method, params, null);
};

export const changePriceForRequest = (requestId, amount) => {
  const url = '/new-ops/offline-consult/change-price';
  const method = 'POST';
  const data = {
    requestId,
    amount,
  };
  return baseRequest(url, method, null, data);
};

export const fetchOpsUsers = () => {
  const url = '/new-ops/offline-consult/ops-users';
  const method = 'GET';
  return baseRequest(url, method, null, null).then((response) => response.data);
};

export const fetchClaimedOpsUsers = () => {
  const url = '/new-ops/offline-consult/ops-users-claimed';
  const method = 'GET';
  return baseRequest(url, method, null, null).then((response) => response.data);
};

export const placeCall = (reimbursementId) => {
  const url = `/voice-call/initiate`;
  const method = 'POST';
  const body = {
    eventId: reimbursementId,
    eventType: 'con-reimburse',
  };
  return baseRequest(url, method, null, body);
};

export const reopentNoShow = (requestId) => {
  const url = '/new-ops/reopen-no-show';
  const method = 'POST';
  return baseRequest(url, method, null, { requestId });
};

export const fetchPrescriptionsForRequestId = (requestId) => {
  const url = `/new-ops/offline-consult/${requestId}/prescription`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const shiftOfflineConsultationStatusToPending = (requestId) => {
  const url = `/new-ops/offline-consult/${requestId}/shift-to-pending`;
  const method = 'PUT';
  return baseRequest(url, method, null, null);
};

export const shiftOfflineConsultationToReimbursement = (requestId) => {
  const url = `/new-ops/offline-consult/${requestId}/shift-to-reimbursement`;
  const method = 'PUT';
  return baseRequest(url, method, null, null);
};

export const addAssigneToAppointmentReq = (requestId) => {
  const url = `/new-ops/offline-consult/${requestId}/add-assigne`;
  const method = 'PUT';
  return baseRequest(url, method, null, null);
};

export const fetchTransactionHistory = (requestId) => {
  const url = `/new-ops/requests/${requestId}/transaction-history`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const fetchDoctorPriceChangeHistory = (body) => {
  const url = `/new-ops/offline-consult/doctor-history`;
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const fetchUserPolicies = (userId) => {
  const url = `/new-ops/getUsersPolicies/${userId}/for-offline-bookings`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const fetchUserPoliciesByPhone = (phone) => {
  const url = `/new-ops/getUsersPolicies/for-offline-bookings-by-phone`;
  const method = 'GET';
  const params = {
    phone,
  };
  return baseRequest(url, method, params, null);
};

export function fetchDoctorPracticeCenters(doctorId) {
  const url = `/new-ops/practice/doctor-practice/${doctorId}`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
}

export function fetchDoctorSlots(doctorId) {
  const url = `/new-ops/practice/doctor-slots/${doctorId}`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
}

export function deleteDoctorSlot(slotId, centerId, doctorId) {
  const url = `/new-ops/practice/doctor-slots/${doctorId}`;
  const body = {
    doctorSlotId: slotId,
    networkCenterId: centerId,
  };
  const method = 'DELETE';
  return baseRequest(url, method, null, body);
}

export function persistDoctorSlots(doctorId, slots) {
  const url = `/new-ops/practice/doctor-slots/${doctorId}`;
  const method = 'POST';
  return baseRequest(url, method, null, slots);
}

export function updateOnCallBasis(appointmentPayload) {
  const url = `/new-ops/practice/doctor-practice/on-call`;
  const method = 'PUT';
  return baseRequest(url, method, null, appointmentPayload);
}

export function updatePracticeCenterActiveStatus(
  doctorId,
  doctorPracticeId,
  status
) {
  const url = `/new-ops/practice/doctor-practice/status`;
  const method = 'POST';
  const body = {
    doctorId,
    centerId: doctorPracticeId,
    active: status ? 1 : 0,
  };
  return baseRequest(url, method, null, body);
}

export function updateAppointmentType(appointmentPayload) {
  const url = `/new-ops/practice/doctor-practice/appointment-type`;
  const method = 'PUT';
  return baseRequest(url, method, null, appointmentPayload);
}

export function updateShowSameDaySlotsStatus(details) {
  const url = `/new-ops/practice/doctor-practice/update-same-day-slots-status`;
  const method = 'POST';
  return baseRequest(url, method, null, details);
}

export function updateTimeRange(details) {
  const url = `/new-ops/practice/doctor-practice/set-lead-time`;
  const method = 'POST';
  return baseRequest(url, method, null, details);
}

export function getCommsInfo(requestId) {
  const url = `/new-ops/get-comms-info`;
  const method = 'GET';
  const data = {
    requestId,
  };
  return baseRequest(url, method, data, null);
}

export function retriggerRequest(requestId, eventType) {
  const url = `/new-ops/retrigger-comms`;
  const method = 'POST';
  const data = {
    requestId,
    eventType,
  };
  return baseRequest(url, method, null, data);
}

export function fetchCancelledReasons(requestId) {
  const url = `/new-ops/cancel/cancelled-reason/${requestId}`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
}

export const markEscallation = (requestId, editComment) => {
  if (!requestId) {
    throw new Error('No Request Id Found');
  }
  const body = { comment: editComment };
  const url = `/new-ops/requests/${requestId}/escalation`;
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const sendRequestForRefundFromOps = (requestId,refundType)=>{
  if (!requestId) {
    throw new Error('No Request Id Found');
  }
  const body = {refundType:refundType};
  const url = `/new-ops/requests/${requestId}/initiate-refund`;
  const method = 'POST';
  return baseRequest(url, method, null, body);
}

export const bookApiAppointment = ({
  requestId,
  externalSlotInfo,
  centerKey
}) => {
  const url = `/new-ops//offline-consult/${requestId}/external-booking`;
  const method = 'POST';
  const data = {
    externalSlotInfo,
    centerKey
  };
  return baseRequest(url, method, null, data);
};
export const getOPDAgentStatus = () => {
  const url = `/new-ops/auto-assignee/get-ops-agent-status`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const markOpsUserCheckIn = () => {
  const url = `/new-ops/auto-assignee/ops-agent-check-in`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const markOpsUserCheckOut = () => {
  const url = `/new-ops/auto-assignee/ops-agent-check-out`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const markRequestAsStuck = (selectedReason, requestId, remarks) => {
  const body = {
    selectedReason,
    requestId,
    remarks
  }
  const url = `/new-ops/auto-assignee/mark-stuck-request`;
  const method = 'POST';
  return baseRequest(url, method, null, body);
};
