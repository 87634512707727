import React, { Component } from 'react';
import loginCheck from '../login/LoginCheck';
import { Async, components } from 'react-select';
import ReactTable from 'react-table';
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import AsyncSelect from 'react-select/lib/Async';
import 'react-table/react-table.css';
import {
  Button,
  ButtonGroup,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip,
  Input as InputBox,
} from 'reactstrap';
import { Select as AntdSelect } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import StatusFilter from './headerFilter/StatusFilter';
import SponsorFilter from './headerFilter/SponsorFilter';
import AckFilter from './headerFilter/AckFilter';
import InHouseFilter from './headerFilter/InHouseFilter';
import PayoutFilter from './headerFilter/PayoutFilter';
import DateFilter from './headerFilter/DateFilter';
import OnboardingStatusFilter from './headerFilter/OnboardingStatusFilter';
import CommunicationsSetup from './communications/CommunicationsSetup';

import ClaimRequestModal from './ClaimRequestModal';

import SubComponent from './table-subcomponent/SubComponent';

import { toast } from 'react-toastify';

import {
  FaSearch,
  FaTicketAlt,
  FaArrowAltCircleUp,
  FaMoneyCheckAlt,
  FaClipboardCheck,
  FaRupeeSign,
  FaPlus,
} from 'react-icons/fa';

import { getRequestTicketsLink } from '../../services/api/tickets';

//Axios
import 'bootstrap//dist/css/bootstrap.css';

import {
  IoIosCheckmark,
  IoMdCreate,
  IoMdClose,
  IoMdChatboxes,
  IoIosMailOpen,
  IoMdPerson,
  IoMdClipboard,
  IoMdContacts,
  IoMdAlarm,
  IoMdTrendingUp,
  IoIosAddCircle
} from 'react-icons/io';
import { MdTransform } from 'react-icons/md';
import payableIcon from '../../images/payable.svg'
import nonPayableIcon from '../../images/nonPayable.svg'
// Modal
import OfflineModalConfirm from './OfflineModalConfirm';
import OfflineModalCancel from './OfflineModalCancel';
import AddAssigneModal from './AddAssigneModal';
import PlaceRequest from './PlaceRequest';
import ConfirmModal from './ConfirmModal';
import OfflineModalNonPayable from './offlineModalNonPayable';
import OfflineModalPayable from './offlineModalPayable';

import EditUserPaymentModal from './EditUserPaymentModal';

import {
  showRequestModal,
  hideRequestModal,
  hideDoctorSlotsEditModal,
  showDoctorSlotsEditModal,
  showInternalComments,
  hideInternalComments,
} from '../../actions/index';

import { debounce } from '../../utils/debounce';

import './modal.css';

import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import { Row, Col } from 'reactstrap';

import styled from 'styled-components';

import {
  StatusPendingContainer,
  StatusCompleteContainer,
  StatusDangerContainer,
} from '../common/StatusContainer';

import LegendContent from './LegendContent';

import DownloadCsv from '../common/DownloadCsv';

import {
  getAllOfflineRequests,
  getAllOfflineRequestsCount,
  getDoctorSelectionList,
  confirmRequest,
  confirmDoctorAppointmentRequest,
  cancelRequest,
  placeRequest,
  callUserRequest,
  callDoctorRequest,
  convertConsultToReimbursement,
  shiftOfflineConsultationStatusToPending,
  shiftOfflineConsultationToReimbursement,
  requestReconfirmationForOfflineConsult,
  appointDoctorRequest,
  sendPriceChangeRequestAPI,
  addAssigneToAppointmentReq,
  fetchFollowUp,
  fetchCancelledReasons,
  providerClaimsRequest
} from '../../services/api/offline-consult';

import NavBar from '../layout/NavBar';
import RequestDetails from './RequestDetails';
import RequestChatModal from './request-comments/modal';

import CallingButton from './CallingButton';

import NetworkSourceFilter from './headerFilter/NetworkSourceFilter';
import ClaimedByFilter from './headerFilter/ClaimedByFilter';
import SuggestionsDialog from './SuggestionsDialog';
import ShiftingDetailsDialog from './ShiftingDetailsDialog';
import ChangeProcessingDateModal from './ChangeProcessingDateModal';
import { RegularText } from '../common/Text';
import StyledTappable from '../common/StyledTappable';
import LoadingComponent from '../common/LoadingComponent';
import NetworkCenterIcon from './table-subcomponent/NetworkCenterIcon';
import {
  fetchAllSponsors,
  fetchAppointmentPreference,
} from '../../services/api/sponsors';
import Select from 'react-select';
import RescheduleAppointMentRequest from './RescheduleAppointMentRequest';
import OfflineApiBookingModal from './OfflineApiBooking';
import FollowUpModal from './FollowUpModal';
import FetchFollowUpModal from './FetchFollowUpModal';
import AddDoctorDetailModal from './AddDoctorDetailModal';
import EditDoctorSlots from './EditDoctorSlots';
import PendingFollowUpFilter from './headerFilter/PendingFollowUpFilter';
import MarkEscallationModal from './MarkEscallationModal';

const { Option } = AntdSelect;

const mobileNumberValidationRegex = /^[6789]\d{9}$/;
const emailValidationRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const FoldableTable = FoldableTableHOC(ReactTable);

const PaddedContainer = styled.div`
  padding: 5px;
`;

const OuterComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '80px',
    zIndex: 9999,
  }),
};

const SearchBox = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
`;

const SerachBoxInput = styled(Input)`
  border: 0;
  flex: 3 0 0;
  margin: 1px;
  &:focus {
    outline: none;
  }
`;

const SearchBoxContent = styled.div`
  display: inline-block;
  flex: 1 0 0;
  margin: 1px;
  text-align: right;
`;

const ColumnMultiFilterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

const ProcessingDateComponent = styled(StyledTappable)``;

const ProcessingDate = styled(RegularText)`
  display: flex;
  font-size: 16px;
  color: ${(props) => props.color || '#46471a'};
`;

const DefaultRequestDateFilter = () => {
  return {
    startDate: moment().subtract(31, 'days'),
    endDate: moment(),
  };
};
class OfflineConsultation extends Component {
  tableAllColumns = (prebooked, status, providerClaims) => [
    {
      Header: 'Request Details',
      foldable: true,
      columns: [
        {
          Header: () => {
            return (
              <div>
                <input
                  type="checkbox"
                  name="All"
                  checked={this.state.selectAllChecked}
                  placeholder="All"
                  onChange={this.handleAllChangeCheckbox}
                />{' '}
                All
              </div>
            );
          },
          filterable: false,
          Cell: (row) => {
            return (
              <input
                value={row.original.requestId}
                checked={this.state.allChecked.includes(row.original.requestId)}
                type="checkbox"
                onChange={this.handleChangeCheckbox}
              />
            );
          },
        },
        {
          accessor: 'requestId',
          numeric: true,
          disablePadding: true,
          // filterable: false,
          Filter: (props) => (
            <input
              onChange={(event) =>
                debounce(this.setRequestIdFilter(event), 300)
              }
            />
          ),
          Header: 'RID',
          style: { whiteSpace: 'unset' },
          Cell: (row) => {
            console.log('props offline', this.props);
            const {
              requestId,
              appointmentDate,
              tpaFailed,
              tpaCancelledAt,
              callSid,
              centerRequest,
              escalatedRequest,
            } = row.original;
            this.state.centerRequestStatus = row.original.centerRequestStatus;
            let color = '#007bff',
              borderColor = '#007bff';
            if (tpaFailed) {
              color = '#F93154';
              borderColor = '#F93154';
            }
            if (tpaCancelledAt) {
              color = '#ffc107';
              borderColor = '#ffc107';
            }
            if (callSid) {
              color = '#FF6F00';
              borderColor = '#FF6F00';
            }
            return (
              <div
                style={{
                  backgroundColor: row.original.escalatedRequest
                    ? '#FFCC80'
                    : row.original.isVipUser
                    ? '#b78d78'
                    : 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '0.5rem',
                  borderRadius: '4px',
                }}
              >
                <button
                  style={{
                    backgroundColor: color,
                    borderRadius: '0.2rem',
                    borderColor,
                    color: 'white',
                    border: `4px solid ${borderColor}`,
                  }}
                  onClick={() => this.showDetails(requestId, row.original)}
                >
                  {requestId}
                </button>
                &nbsp;&nbsp;{row.original.isVipUser ? 'VIP' : ''} <br></br>
                &nbsp;&nbsp;{row.original.isDelayedRequest ? 'DELAYED' : ''}
              </div>
            );
          },
        },
        {
          accessor: 'claimedUserName',
          numeric: false,
          disablePadding: false,
          Header: 'Claimed By',
          style: { whiteSpace: 'unset' },
          Filter: ({ filter, onChange }) => (
            <ClaimedByFilter
              onChange={onChange}
              value={filter ? filter.value : ''}
            />
          ),
          Cell: (row) => {
            const { claimedUserName } = row.original;
            if (claimedUserName) {
              return claimedUserName;
            } else {
              return '';
            }
          },
        },
        providerClaims ? {
          accessor: 'status',
          numeric: false,
          disablePadding: false,
          Header: 'Status',
          style: { whiteSpace: 'unset' },
          sortable: false,
          Filter: (props) => (
            <StatusFilter
              onChangeValue={this.changeStatusFilter}
              value={this.state.statusFilter}
              segregation={this.state.segregation}
              {...props}
            />
          ),
          Cell: (row) => {
            const reasonMap = {
              'I was not informed about the rescheduling':
                'User was uninformed',
              'I am not comfortable with the timeslot': 'Time issue',
              'I requested for a different doctor': 'Doctor issue',
              'I wanted an appointment for a different date': 'Date issue',
              'I am not comfortable with the consultation fee':
                'Consultation fee',
              'I requested for a different centre': 'Centre issue',
            };
            const {
              status,
              rescheduledTimeDiff,
              subCancelReason,
              userConfirmed,
              hospitalRescheduleId,
              concernReason,
              priceApprovalStatus,
              opsRescheduledRequest,
            } = row.original;
            console.log(status, 'status123', subCancelReason);
            const shortReason =
              reasonMap[concernReason] || concernReason
                ? ` - (User Escalated) ${
                    reasonMap[concernReason] || concernReason
                  }`
                : '';
            if (
              priceApprovalStatus === 'Pending' &&
              (status === 'Apt. Pending' || status === 'Confirmed')
            ) {
              return (
                <StatusPendingContainer>
                  Price Approval Pending
                </StatusPendingContainer>
              );
            } else if (
              hospitalRescheduleId &&
              parseInt(rescheduledTimeDiff) <= 2 &&
              !userConfirmed &&
              concernReason
            ) {
              return (
                <StatusPendingContainer>
                  User Escalated{shortReason}
                </StatusPendingContainer>
              );
            } else if (
              hospitalRescheduleId &&
              parseInt(rescheduledTimeDiff) <= 2 &&
              !userConfirmed &&
              opsRescheduledRequest
            ) {
              return (
                <StatusPendingContainer>
                  Confirmation Awaited
                </StatusPendingContainer>
              );
            } else if (
              hospitalRescheduleId &&
              parseInt(rescheduledTimeDiff) <= 2 &&
              !userConfirmed
            ) {
              return (
                <StatusPendingContainer>
                  Appt. Rescheduled
                </StatusPendingContainer>
              );
            } else if (
              parseInt(rescheduledTimeDiff) &&
              parseInt(rescheduledTimeDiff) > 2 &&
              !userConfirmed
            ) {
              return <StatusPendingContainer>Call User</StatusPendingContainer>;
            } else if (
              status === 'Payment Pending' ||
              status === 'Rx Pending'
            ) {
              return <StatusPendingContainer>{status}</StatusPendingContainer>;
            } else if (status === 'Rx Uploaded') {
              return (
                <StatusCompleteContainer>{status}</StatusCompleteContainer>
              );
            } else if (status === 'User Cancelled' && subCancelReason) {
              return (
                <>
                  {status} ({subCancelReason}){shortReason}
                </>
              );
            }
            return `${status}${shortReason}`;
          },
        } : 
        {
          accessor: 'status',
          numeric: false,
          disablePadding: false,
          Header: 'Status',
          style: { whiteSpace: 'unset' },
          sortable: false,
          Filter: (props) => (
            <StatusFilter
              onChangeValue={this.changeStatusFilter}
              value={this.state.statusFilter}
              segregation={this.state.segregation}
              {...props}
            />
          ),
          Cell: (row) => {
            const reasonMap = {
              'I was not informed about the rescheduling':
                'User was uninformed',
              'I am not comfortable with the timeslot': 'Time issue',
              'I requested for a different doctor': 'Doctor issue',
              'I wanted an appointment for a different date': 'Date issue',
              'I am not comfortable with the consultation fee':
                'Consultation fee',
              'I requested for a different centre': 'Centre issue',
            };
            const {
              status,
              rescheduledTimeDiff,
              subCancelReason,
              userConfirmed,
              hospitalRescheduleId,
              concernReason,
              priceApprovalStatus,
              opsRescheduledRequest,
            } = row.original;
            console.log(status, 'status123', subCancelReason);
            const shortReason =
              reasonMap[concernReason] || concernReason
                ? ` - (User Escalated) ${
                    reasonMap[concernReason] || concernReason
                  }`
                : '';
            if (
              priceApprovalStatus === 'Pending' &&
              (status === 'Apt. Pending' || status === 'Confirmed')
            ) {
              return (
                <StatusPendingContainer>
                  Price Approval Pending
                </StatusPendingContainer>
              );
            } else if (
              hospitalRescheduleId &&
              parseInt(rescheduledTimeDiff) <= 2 &&
              !userConfirmed &&
              concernReason
            ) {
              return (
                <StatusPendingContainer>
                  User Escalated{shortReason}
                </StatusPendingContainer>
              );
            } else if (
              hospitalRescheduleId &&
              parseInt(rescheduledTimeDiff) <= 2 &&
              !userConfirmed &&
              opsRescheduledRequest
            ) {
              return (
                <StatusPendingContainer>
                  Confirmation Awaited
                </StatusPendingContainer>
              );
            } else if (
              hospitalRescheduleId &&
              parseInt(rescheduledTimeDiff) <= 2 &&
              !userConfirmed
            ) {
              return (
                <StatusPendingContainer>
                  Appt. Rescheduled
                </StatusPendingContainer>
              );
            } else if (
              parseInt(rescheduledTimeDiff) &&
              parseInt(rescheduledTimeDiff) > 2 &&
              !userConfirmed
            ) {
              return <StatusPendingContainer>Call User</StatusPendingContainer>;
            } else if (
              status === 'Payment Pending' ||
              status === 'Rx Pending'
            ) {
              return <StatusPendingContainer>{status}</StatusPendingContainer>;
            } else if (status === 'Rx Uploaded') {
              return (
                <StatusCompleteContainer>{status}</StatusCompleteContainer>
              );
            } else if (status === 'User Cancelled' && subCancelReason) {
              return (
                <>
                  {status} ({subCancelReason}){shortReason}
                </>
              );
            }
            return `${status}${shortReason}`;
          },
        },
        {
          // accessor: 'rescheduled',
          // numeric: false,
          filterable: true,
          // disablePadding: false,
          Header: 'Pending Follow Ups',
          style: { whiteSpace: 'unset' },
          // Filter: (props) => (
          //   <input
          //     onChange={(event) =>
          //       debounce(this.setRequestIdFilter(event), 300)
          //     }
          //   />
          // ),
          Filter: (props) => {
            return (
              <PendingFollowUpFilter
                {...props}
                onChangeValue={this.changePendingFollowUpFitler}
              />
            );
          },
          Cell: (row) => {
            const {
              requestId,
              dueFollowUpCount,
              totalFollowUpCount,
              uncompletedFollowUpCount,
            } = row.original;
            let followUpToShow;

            if (dueFollowUpCount != 0) {
              followUpToShow = `Yes (${dueFollowUpCount})`;
            } else if (dueFollowUpCount == 0 && uncompletedFollowUpCount != 0) {
              followUpToShow = 'No';
            } else {
              followUpToShow = 'None';
            }

            return (
              <div>
                <p
                  className=""
                  id={`fetchfollowUpButton-${row.original.requestId}`}
                  onClick={() => this.openFetchFollowUpModal(row.original)}
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.textDecoration = 'underline')
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.textDecoration = 'none')
                  }
                >
                  {followUpToShow}
                </p>
                <Tooltip
                  placement="top"
                  target={`fetchfollowUpButton-${row.original.requestId}`}
                  trigger="hover"
                  toggle={() => {
                    const { markFetchFollowUpModalOpen } = this.state;
                    this.setState({
                      markFetchFollowUpModalOpen:
                        row.original.requestId === markFetchFollowUpModalOpen
                          ? -1
                          : row.original.requestId,
                    });
                  }}
                  isOpen={
                    this.state.markFetchFollowUpModalOpen ===
                    row.original.requestId
                  }
                >
                  Show Follow Ups
                </Tooltip>
              </div>
            );
          },
        },
        prebooked
          ? {
              accessor: 'actions',
              numeric: true,
              filterable: false,
              disablePadding: false,
              Header: 'Actions',
              style: { whiteSpace: 'unset', width: '10px' },
              Cell: (row) => {
                const { centerRequest } = row.original;
                this.state.centerRequest = centerRequest;
               
                if (!this.state.centerRequest) {
                  return (
                    <div className="flex flex-wrap items-center">
                      <IoMdClose
                        className="icon-style"
                        id={`cancelButton-${row.original.requestId}`}
                        onClick={() =>
                          this.cancelOpenModal(
                            row.original.requestId,
                            row.original.priceApprovalStatus
                          )
                        }
                      />
                      <Tooltip
                        placement="top"
                        target={`cancelButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { cancelTooltipOpen } = this.state;
                          this.setState({
                            cancelTooltipOpen:
                              row.original.requestId === cancelTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.cancelTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Cancel Appointment
                      </Tooltip>
                    </div>
                  );
                } else {
                  return (
                    <div className="flex flex-wrap items-center">
                      <IoMdClose
                        className="icon-style"
                        id={`cancelButton-${row.original.requestId}`}
                        onClick={() =>
                          this.cancelOpenModal(row.original.requestId)
                        }
                      />
                      <Tooltip
                        placement="center"
                        target={`cancelButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { cancelTooltipOpen } = this.state;
                          this.setState({
                            cancelTooltipOpen:
                              row.original.requestId === cancelTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.cancelTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Cancel Appointment
                      </Tooltip>
                    </div>
                  );
                }
               
              },
            }
          :
          {
              accessor: 'actions',
              numeric: true,
              filterable: false,
              disablePadding: false,
              Header: 'Actions',
              sortable: false,
              style: { whiteSpace: 'unset' },
              Cell: (row) => {
                const ticketAvailable = row.original.ticketAvailable;
                const reconfirmationRequested =
                  row.original.reconfirmationRequested;
                const { centerRequest } = row.original;
                this.state.centerRequest = centerRequest;
                const { networkCenterId } = row.original;
                if(providerClaims) {
                  return (
                    <div className="flex flex-wrap items-center">
                      <img
                        src={payableIcon}
                        className="icon-style"
                        id={`claimButton-${row.original.requestId}`}
                        onClick={() =>
                          this.setState({payableClaimsOpenModal: true, requestId: row.original.requestId})
                        }
                      />
                      <Tooltip
                        placement="center"
                        target={`claimButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { payableTooltipOpen } = this.state;
                          this.setState({
                            payableTooltipOpen:
                              row.original.requestId === payableTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.payableTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Payable Claim
                      </Tooltip>

                      <img
                        src={nonPayableIcon}
                        className="icon-style"
                        id={`cancelButton-${row.original.requestId}`}
                        onClick={() =>
                          this.setState({nonPayableClaimsOpenModal: true, requestId: row.original.requestId})
                        }
                      />
                      <Tooltip
                        placement="center"
                        target={`cancelButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { nonPayableTooltipOpen } = this.state;
                          this.setState({
                            nonPayableTooltipOpen:
                              row.original.requestId === nonPayableTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.nonPayableTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Non-payable claim
                      </Tooltip>
                    </div>
                  );
                } else {
                if (!this.state.centerRequest) {
                  return (
                    <div className="flex flex-wrap items-center">
                      <IoIosCheckmark
                        className="icon-style"
                        id={`confirmButton-${row.original.requestId}`}
                        onClick={() =>
                          this.confirmOpenModal(
                            row.original.requestId,
                            row.original.priceApprovalStatus,
                            row.original
                          )
                        }
                        style={{ fontSize: '25px' }}
                      />
                      <Tooltip
                        placement="top"
                        target={`confirmButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { confirmTooltipOpen } = this.state;
                          this.setState({
                            confirmTooltipOpen:
                              row.original.requestId === confirmTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.confirmTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Confirm Appointment
                      </Tooltip>
                      <IoMdClose
                        className="icon-style"
                        id={`cancelButton-${row.original.requestId}`}
                        onClick={() =>
                          this.cancelOpenModal(
                            row.original.requestId,
                            row.original.priceApprovalStatus
                          )
                        }
                      />
                      <Tooltip
                        placement="top"
                        target={`cancelButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { cancelTooltipOpen } = this.state;
                          this.setState({
                            cancelTooltipOpen:
                              row.original.requestId === cancelTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.cancelTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Cancel Appointment
                      </Tooltip>
                      <IoIosMailOpen
                        className="icon-style"
                        id={`communicateButton-${row.original.requestId}`}
                        onClick={() => this.openCommunications(row.original)}
                      />
                      <Tooltip
                        placement="top"
                        target={`communicateButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { sendCommunicationTooltipOpen } = this.state;
                          this.setState({
                            sendCommunicationTooltipOpen:
                              row.original.requestId ===
                              sendCommunicationTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.sendCommunicationTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Send Communication
                      </Tooltip>
                      <IoMdCreate
                        className="icon-style"
                        id={`editPaymentButton-${row.original.requestId}`}
                        onClick={() =>
                          this.openEditUserPayment(row.original.requestId)
                        }
                      />
                      <Tooltip
                        placement="top"
                        target={`editPaymentButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { editPaymentTooltipOpen } = this.state;
                          this.setState({
                            editPaymentTooltipOpen:
                              row.original.requestId === editPaymentTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.editPaymentTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Edit Payment
                      </Tooltip>
                      {(row.original.sponsorName === 'Akzo' ||
                        row.original.sponsorName === 'Marsh' ||
                        row.original.sponsorName === 'Johnson & Johnson') && (
                        <>
                          <MdTransform
                            className="icon-style"
                            id={`convertButton-${row.original.requestId}`}
                            onClick={this.confirmConvertToReimbursementRequest(
                              row.original
                            )}
                          />
                          <Tooltip
                            placement="top"
                            target={`convertButton-${row.original.requestId}`}
                            trigger="hover"
                            toggle={() => {
                              const { convertReimbursementTooltipOpen } =
                                this.state;
                              this.setState({
                                convertReimbursementTooltipOpen:
                                  row.original.requestId ===
                                  convertReimbursementTooltipOpen
                                    ? -1
                                    : row.original.requestId,
                              });
                            }}
                            isOpen={
                              this.state.convertReimbursementTooltipOpen ===
                              row.original.requestId
                            }
                          >
                            Convert to Reimbursement
                          </Tooltip>
                        </>
                      )}
                      <IoMdPerson
                        className="icon-style"
                        id={`claimButton-${row.original.requestId}`}
                        onClick={() =>
                          this.openClaimRequestButton(row.original.requestId)
                        }
                      />
                      <Tooltip
                        placement="top"
                        target={`claimButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { claimTooltipOpen } = this.state;
                          this.setState({
                            claimTooltipOpen:
                              row.original.requestId === claimTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.claimTooltipOpen === row.original.requestId
                        }
                      >
                        Claim Request
                      </Tooltip>
                      <IoMdClipboard
                        className="icon-style"
                        id={`suggestionsButton-${row.original.requestId}`}
                        onClick={() => this.openSuggestions(row.original)}
                      />
                      <Tooltip
                        placement="top"
                        target={`suggestionsButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { alternateSuggestionsTooltipOpen } =
                            this.state;
                          this.setState({
                            alternateSuggestionsTooltipOpen:
                              row.original.requestId ===
                              alternateSuggestionsTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.alternateSuggestionsTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        View Alternate Suggestions
                      </Tooltip>
                      <IoMdContacts
                        className="icon-style"
                        id={`transferButton-${row.original.requestId}`}
                        onClick={() => this.openShiftingModal(row.original)}
                      />
                      <Tooltip
                        placement="top"
                        target={`transferButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { transferTooltipOpen } = this.state;
                          this.setState({
                            transferTooltipOpen:
                              row.original.requestId === transferTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.transferTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Reschedule Request
                      </Tooltip>
                      { !row.original.prebookedAppointment && row.original.externalApiLogsId && !row.original.externalBookingId && !row.original.cancelledAt && (<IoIosAddCircle
                        className="icon-style"
                        id={`apiBookingButton-${row.original.requestId}`}
                        onClick={() => this.openApiBookingModal(row.original)}
                      /> )}
                      { !row.original.prebookedAppointment && row.original.externalApiLogsId && !row.original.externalBookingId && !row.original.cancelledAt && (<Tooltip
                        placement="top"
                        target={`apiBookingButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { apiBookingTooltipOpen } = this.state;
                          this.setState({
                            apiBookingTooltipOpen:
                              row.original.requestId === apiBookingTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.apiBookingTooltipOpen ===
                          row.original.requestId
                        }
                      >
                       Book API Appointment
                      </Tooltip> )}

                      {ticketAvailable && (
                        <a
                          href={getRequestTicketsLink(row.original.requestId)}
                          target="_blank"
                        >
                          <FaTicketAlt className="icon-style" />
                        </a>
                      )}
                      <FaArrowAltCircleUp
                        className="icon-style"
                        id={`shiftToPendingButton-${row.original.requestId}`}
                        onClick={() =>
                          this.toggleShiftToPendingModal(row.original.requestId)
                        }
                      />
                      <Tooltip
                        placement="top"
                        target={`shiftToPendingButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { shiftToPendingOpen } = this.state;
                          this.setState({
                            shiftToPendingOpen:
                              row.original.requestId === shiftToPendingOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.shiftToPendingOpen ===
                          row.original.requestId
                        }
                      >
                        Shift To Pending
                      </Tooltip>

                      {/*Request Reconfirmation*/}
                      {!reconfirmationRequested && (
                        <>
                          <FaClipboardCheck
                            className="icon-style"
                            id={`requestReconfirmationButton-${row.original.requestId}`}
                            onClick={() =>
                              this.toggleRequestReconfirmationModal(
                                row.original.requestId
                              )
                            }
                          />
                          <Tooltip
                            placement="top"
                            target={`requestReconfirmationButton-${row.original.requestId}`}
                            trigger="hover"
                            toggle={() => {
                              const { requestReconfirmation } = this.state;
                              this.setState({
                                requestReconfirmation:
                                  row.original.requestId ===
                                  requestReconfirmation
                                    ? -1
                                    : row.original.requestId,
                              });
                            }}
                            isOpen={
                              this.state.requestReconfirmation ===
                              row.original.requestId
                            }
                          >
                            Request Reconfirmation
                          </Tooltip>
                        </>
                      )}

                      {/*    Shift to reimbursement*/}

                      <FaMoneyCheckAlt
                        className="icon-style"
                        id={`shiftToReimbursementButton-${row.original.requestId}`}
                        onClick={() =>
                          this.toggleShiftToReimbursementModal(
                            row.original.requestId
                          )
                        }
                      />
                      <Tooltip
                        placement="top"
                        target={`shiftToReimbursementButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { shiftToReimbursementOpen } = this.state;
                          this.setState({
                            shiftToReimbursementOpen:
                              row.original.requestId ===
                              shiftToReimbursementOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.shiftToReimbursementOpen ===
                          row.original.requestId
                        }
                      >
                        Shift To Reimbursement
                      </Tooltip>

                      <FaPlus
                        className="icon-style"
                        id={`addAssigneButton-${row.original.requestId}`}
                        onClick={() =>
                          this.toggleAddAssigneModal(row.original.requestId)
                        }
                      />
                      <Tooltip
                        placement="top"
                        target={`addAssigneButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { addAssigneOpen } = this.state;
                          this.setState({
                            addAssigneOpen:
                              row.original.requestId === addAssigneOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.addAssigneOpen === row.original.requestId
                        }
                      >
                        Add a assigne
                      </Tooltip>

                      <FaRupeeSign
                        className="icon-style"
                        id={`requestPriceChanges-${row.original.requestId}`}
                        onClick={() =>
                          this.togglePriceChangeRequest(row.original)
                        }
                      />
                      <Tooltip
                        placement="top"
                        target={`requestPriceChanges-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { priceChangeModalOpen } = this.state;
                          this.setState({
                            priceChangeModalOpen:
                              row.original.requestId === priceChangeModalOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.priceChangeModalOpen ===
                          row.original.requestId
                        }
                      >
                        Request Price Changes
                      </Tooltip>
                      {/*Request Follow Up */}
                      <IoMdAlarm
                        className="icon-style"
                        id={`followUpButton-${row.original.requestId}`}
                        onClick={() => this.openFollowUpModal(row.original)}
                      />
                      <Tooltip
                        placement="top"
                        target={`followUpButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { markFollowUpModalOpen } = this.state;
                          this.setState({
                            markFollowUpModalOpen:
                              row.original.requestId === markFollowUpModalOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.markFollowUpModalOpen ===
                          row.original.requestId
                        }
                      >
                        Mark Follow-Up
                      </Tooltip>
                      {/*Mark Escallations*/}
                      <IoMdTrendingUp
                        className="icon-style"
                        id={`markEscallation-${row.original.requestId}`}
                        onClick={() => this.openEscallationModal(row.original)}
                      />
                      <Tooltip
                        placement="bottom"
                        target={`markEscallation-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { markEscallationModalOpen } = this.state;
                          this.setState({
                            markEscallationModalOpen:
                              row.original.requestId ===
                              markEscallationModalOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.markEscallationModalOpen ===
                          row.original.requestId
                        }
                      >
                        Mark Escallation
                      </Tooltip>
                    </div>
                  );
                } else {
                  return (
                    <div className="flex flex-wrap items-center">
                      <IoMdPerson
                        className="icon-style"
                        id={`claimButton-${row.original.requestId}`}
                        onClick={() =>
                          this.openDoctorSelectionModal(
                            row.original.networkCenterId,
                            row.original.centerRequestVertical,
                            row.original.requestId
                          )
                        }
                      />
                      <Tooltip
                        placement="center"
                        target={`claimButton-${row.original.requestId}`}
                        trigger="hover"
                      >
                        Appoint Doctor
                      </Tooltip>

                      <IoMdClose
                        className="icon-style"
                        id={`cancelButton-${row.original.requestId}`}
                        onClick={() =>
                          this.cancelOpenModal(row.original.requestId)
                        }
                      />
                      <Tooltip
                        placement="center"
                        target={`cancelButton-${row.original.requestId}`}
                        trigger="hover"
                        toggle={() => {
                          const { cancelTooltipOpen } = this.state;
                          this.setState({
                            cancelTooltipOpen:
                              row.original.requestId === cancelTooltipOpen
                                ? -1
                                : row.original.requestId,
                          });
                        }}
                        isOpen={
                          this.state.cancelTooltipOpen ===
                          row.original.requestId
                        }
                      >
                        Cancel Appointment
                      </Tooltip>
                    </div>
                  );
                }
               }
              },
            },
        {
          accessor: 'sponsorName',
          numeric: false,
          disablePadding: false,
          Header: 'Sponsor',
          style: { whiteSpace: 'unset', width: '200px' },
          Filter: ({ filter, onChange }) => (
            <Select
              options={this.state.sponsorList}
              placeholder="All"
              onChange={this.handleSponsor}
              // styles={customStyles}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  width: '400px',
                }),
              }}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
            />
          ),
        },
        {
          accessor: 'appointmentDate',
          numeric: false,
          disablePadding: false,
          Header: 'Appt. Date',
          style: { whiteSpace: 'unset' },
          // filterable: false,
          headerStyle: { overflow: 'visible' },
          Filter: (props) => (
            <DateFilter
              label="Appointment"
              value={this.state.appointmentDateFilter}
              {...props}
              onChangeValue={this.changeAppointmentDateFilter}
            />
          ),
        },
        {
          accessor: 'requestDate',
          numeric: false,
          disablePadding: false,
          Header: 'Req. Date',
          style: { whiteSpace: 'unset' },
          // filterable: false,
          headerStyle: { overflow: 'visible' },
          Filter: (props) => (
            <DateFilter
              label="Request"
              value={this.state.requestDateFilter}
              {...props}
              onChangeValue={this.changeRequestDateFilter}
            />
          ),
        },
        {
          accessor: 'processingDate',
          numeric: false,
          disablePadding: false,
          Header: 'Process. Date',
          style: { whiteSpace: 'unset' },
          // filterable: false,
          headerStyle: { overflow: 'visible' },
          Filter: (props) => (
            <DateFilter
              label="Processing"
              value={this.state.processingDateFilter}
              {...props}
              onChangeValue={this.changeProcessingDateFilter}
            />
          ),
          Cell: (row) => {
            return (
              <ProcessingDateComponent
                onTap={() => this.changeProcessingDate(row.original)}
              >
                <ProcessingDate color={row.original.processingDateColor}>
                  {moment(row.original.processingDate * 1000).format(
                    'DD MMM h:mm A'
                  )}
                </ProcessingDate>
              </ProcessingDateComponent>
            );
          },
        },
        {
          accessor: 'patientName',
          numeric: false,
          filterable: true,
          disablePadding: false,
          Header: 'Patient',
          style: { whiteSpace: 'unset' },
          Filter: ({ filter, onChange }) => (
            <ColumnMultiFilterDiv>
              <input
                placeholder={'Search...'}
                style={{ 'max-width': '5.5rem' }}
                onChange={(event) =>
                  debounce(this.setPatientNameFilter(event), 600)
                }
                onKeyDown={(e) =>
                  e.key === 'Enter' ? this.fetchTableData() : null
                }
              />
            </ColumnMultiFilterDiv>
          ),
        },
        {
          accessor: 'doctorName',
          disablePadding: false,
          Header: 'Doctor',
          style: { whiteSpace: 'unset' },
          Cell: (row) => {
            const { onBoardingStatus, doctorName } = row.original;
            if (onBoardingStatus === 'live') {
              return (
                <StatusCompleteContainer>{doctorName}</StatusCompleteContainer>
              );
            } else if (onBoardingStatus === 'pending') {
              return (
                <StatusPendingContainer>{doctorName}</StatusPendingContainer>
              );
            }
            return doctorName;
          },
          Filter: ({ filter, onChange }) => (
            <ColumnMultiFilterDiv>
              <input
                placeholder={'Search...'}
                style={{ 'max-width': '5.5rem' }}
                onChange={(event) =>
                  debounce(this.setDoctorNameFilter(event), 600)
                }
                onKeyDown={(e) =>
                  e.key === 'Enter' ? this.fetchTableData() : null
                }
              />
              <OnboardingStatusFilter
                onChange={this.onChangeOnboardingStatusFilter}
                value={this.state.onboardingStatusFilter}
              />
            </ColumnMultiFilterDiv>
          ),
        },
        {
          accessor: 'networkCenterName',
          disablePadding: false,
          Header: 'Center',
          style: { whiteSpace: 'unset' },
          Filter: ({ filter, onChange }) => (
            <ColumnMultiFilterDiv>
              <input
                placeholder={'Search...'}
                style={{ 'max-width': '5.5rem' }}
                onChange={(event) =>
                  debounce(this.setNetworkCenterNameFilter(event), 600)
                }
                onKeyDown={(e) =>
                  e.key === 'Enter' ? this.fetchTableData() : null
                }
              />
              <NetworkSourceFilter
                onChange={this.onChangeNetworkSourceStatusFilter}
                value={this.state.paymentCycleFilter}
              />
            </ColumnMultiFilterDiv>
          ),
          Cell: (row) => {
            const {
              networkCenterName,
              networkCenterMappingNetworkId,
              networkCenterType,
            } = row.original;
            return (
              <>
                {`${networkCenterName} (${networkCenterType || ''})`}
                <br />
                {networkCenterMappingNetworkId ? <NetworkCenterIcon /> : <></>}
              </>
            );
          },
        },
        {
          accessor: 'appointmentPreferenceOptions',
          numeric: false,
          disablePadding: false,
          Header: 'Appointment Preference',
          style: { whiteSpace: 'unset', width: '200px' },
          Filter: () => (
            <Select
              options={this.state.appointmentPreferenceList}
              placeholder="All"
              onChange={this.handleAppointmentPreference}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  width: '400px',
                }),
              }}
              menuPosition={'fixed'}
            />
          ),
          Cell: (row) => {
            const { appointmentPreference } = row.original;
            if (appointmentPreference) {
              return appointmentPreference;
            } else {
              return '';
            }
          },
        },
      ],
    },
    {
      Header: 'Inhouse Details',
      foldable: true,
      columns: [
        {
          accessor: 'acknowledgementCall',
          disablePadding: false,
          Header: 'Ack. Call',
          style: { whiteSpace: 'unset' },
          sortable: false,
          show: status !== 'confirmed',
          Cell: (row) => {
            const {
              requestId,
              userPhone,
              acknowledgementCallCount,
              acknowledgementCallDuration,
            } = row.original;
            let status = 'Pending';
            if (
              acknowledgementCallDuration &&
              acknowledgementCallDuration !== '0s'
            ) {
              status = `Yes (${acknowledgementCallDuration}, ${acknowledgementCallCount})`;
              return (
                <StatusCompleteContainer>
                  {status}
                  {userPhone && (
                    <CallingButton
                      onSubmit={() => this.setAckButtonConfirmModal(requestId)}
                    />
                  )}
                </StatusCompleteContainer>
              );
            } else if (parseInt(acknowledgementCallCount) !== 0) {
              status = `No Answer (${acknowledgementCallCount})`;
              return (
                <StatusPendingContainer>
                  {status}
                  {userPhone && (
                    <CallingButton
                      onSubmit={() => this.setAckButtonConfirmModal(requestId)}
                    />
                  )}
                </StatusPendingContainer>
              );
            }
            return (
              <StatusDangerContainer>
                {status}
                {userPhone && (
                  <CallingButton
                    onSubmit={() => this.setAckButtonConfirmModal(requestId)}
                  />
                )}
              </StatusDangerContainer>
            );
          },
          Filter: (props) => {
            return <AckFilter {...props} onChange={this.changeAckFitler} />;
          },
        },
        // {
        //   accessor: 'doctorOnCallName',
        //   numeric: true,
        //   disablePadding: false,
        //   Header: 'In House',
        //   style: { whiteSpace: 'unset' },
        //   show: status !== 'confirmed',
        //   Filter: (props) => (
        //     <InHouseFilter onChangeValue={this.setInHouseFilter} {...props} />
        //   ),
        //  Cell: (row) => {
        //     const { rescheduleReqTime } = row.original;
        //     if (rescheduleReqTime) {
        //       return rescheduleReqTime;
        //     } else {
        //       return 'No';
        //     }
        //   },
        // },
      ],
    },
    {
      accessor: 'payoutStatus',
      disablePadding: true,
      filterable: true,
      Header: 'Payout',
      sortable: false,
      style: { whiteSpace: 'unset' },
      Filter: (props) => (
        <PayoutFilter onChangeValue={this.setPayoutFilter} {...props} />
      ),
    },
    {
      accessor: 'requestComments',
      disablePadding: true,
      filterable: false,
      Header: 'Comments',
      sortable: false,
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        const { requestId, countComments } = row.original;
        return (
          <React.Fragment>
            <div onClick={() => this.openRequestChatModal(requestId)}>
              <p>
                <span id={`commentTooltip-a-${requestId}`}>
                  {countComments}
                  <IoMdChatboxes
                    className="icon-chat"
                    style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                  />
                </span>
              </p>
              <Tooltip
                placement="left"
                isOpen={this.state.tooltipOpen === requestId}
                target={`commentTooltip-a-${requestId}`}
                toggle={() => this.toggleCommentTooltip(requestId)}
              />
            </div>
          </React.Fragment>
        );
      },
    },

    {
      disablePadding: true,
      filterable: false,
      Header: 'Center Status',
      sortable: false,
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        const { centerStatus } = row.original;
        if (centerStatus) {
          return 'empanelled';
        } else {
          return 'depanelled';
        }
      },
    },
  ];

  constructor(props) {
    super(props);

    this.debounceSearch = debounce((val) => this.fetchTableData(), 300);
    this.state = {
      loading: true,
      tableColumns: this.tableAllColumns(props.prebooked,null,props.providerClaims),
      data: [],
      totalHits: 0,
      confirmModalIsOpen: false,
      confirmRequest: false,
      altRequest: false,
      cancelModalIsOpen: false,
      nonPayableClaimsOpenModal: false,
      payableClaimsOpenModal: false,
      detail: {},
      comments: [],
      requestChat: {},
      requestChatModal: false,
      cancelRequest: false,
      failRequest: false,
      callRequest: false,
      show: false,
      pages: 0,
      search: '',
      pageSize: 10,
      segregation: null,
      statusFilter: null,
      requestDateFilter: DefaultRequestDateFilter(),
      requestDateDefaultFilterApplied: true,
      processingDateFilter: {
        startDate: null,
        endDate: null,
      },
      appointmentDateFilter: {
        startDate: null,
        endDate: null,
      },
      onboardingStatusFilter: '',
      networkSourceFilter: '',
      paymentCycleFilter: '',
      claimedByFilter: '',
      sponsorFilter: null,
      appointmentPreferenceFilter: null,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      confirmModal: {
        isOpen: false,
        header: '',
        body: '',
        onSubmit: null,
        requestId: null,
        onClose: null,
        buttonLoading: false,
      },
      requestIdFilter: null,
      networkCenterNameFilter: null,
      doctorNameFilter: null,
      patientNameFilter: null,
      onlyVipUsers: null,
      delayedResponse: null,
      ackFilter: null,
      inHouseFilter: null,
      payoutFilter: null,
      pendingFollowUpFilter: null,
      isRescheduledFilter: null,
      isReconfirmationRequiredFilter: null,
      isReconfirmationFilter: null,
      isPendingCallbackFilter: null,
      tooltipOpen: false,
      legendOpen: false,
      openComms: false,
      commsData: false,
      showUserPaymentModal: false,
      showUserPaymentModalRequestId: null,
      showClaimRequestModal: false,
      showClaimRequestModalRequestId: null,
      changeProcessingDateModal: {
        isOpen: false,
        requestId: null,
        processingDate: null,
      },
      shiftingDetails: {},
      followUpDetails: {},
      fetchFollowUpDetails: {},
      confirmTooltipOpen: -1,
      cancelTooltipOpen: -1,
      payableTooltipOpen: -1,
      nonPayableTooltipOpen: -1,
      sendCommunicationTooltipOpen: -1,
      editPaymentTooltipOpen: -1,
      convertReimbursementTooltipOpen: -1,
      claimTooltipOpen: -1,
      markEscallationModalOpen: -1,
      alternateSuggestionsTooltipOpen: -1,
      transferTooltipOpen: -1,
      apiBookingTooltipOpen: -1,
      shiftToPendingOpen: -1,
      shiftToPendingModalOpen: false,
      shiftToPendingRequestId: null,
      escallationRequestId: null,
      shiftReimbursementModalOpen: false,
      addAssigneModalOpen: false,
      addDoctorDetailModalOpen: false,
      shiftToReimbursementReqId: null,
      addAssigneReqId: null,
      shiftToReimbursementOpen: -1,
      addAssigneOpen: -1,
      requestReconfirmationModalOpen: false,
      requestReconfirmationRequestId: null,
      requestReconfirmation: -1,
      doctorSelectionModal: false,
      doctorSelectionCenterId: null,
      doctorSelectionVertical: null,
      doctorSelectionRequestId: null,
      doctorSelectionList: null,
      centerRequest: null,
      centerRequestStatus: null,
      defaultDoctorSearch: null,
      priceChangeRequetsModal: false,
      newPrice: null,
      priceChangeReason: '',
      inputReason: '',
      requestIdDataForPriceChange: {},
      priceChangeModalOpen: -1,
      sponsorList: [],
      appointmentPreferenceList: [],
      requestDataForConfirm: {},
      currentRequestDetails: {},
      currentButtonStatus: null,
      allChecked: [],
      selectAllChecked: false,
      addAssigneFlag: null,
      preBooked: false,
      providerClaims:false,
      cancelledReasons:[],
      callReasonOptions: ["a pending action on the rescheduled request from you",
      "a change in the price",
      "the required reconfirmation from you", 
      "the doctor’s unavailability and available alternatives", 
      "the slot’s unavailability and available alternatives", 
      "a pending confirmation from the centre",
      "an update",
      "a pending prescription upload"],
      isCallModalOpen : false,
      nonPayableClaimsReasons: [
        'he patient did not visit',
        `Patient visited but the appointment wasn’t executed.`,
        'Appointment was cancelled by the centre.',
        'Non-covered users availed the benefit.'
      ],
      payableClaimsReasons: [
        'Rx available/Report available (by default)',
        `The user confirmed on Call that the user visited but lost proof(Rx/report) and verified with the centre about the user's visit`,
        'The user visited but the proof is not as per QC rules (like format, dr stamp missing, etc)'
      ],
      selectedCallReason: null
    };

    this.toggleCommentTooltip = this.toggleCommentTooltip.bind(this);
    this.toggleLegend = this.toggleLegend.bind(this);
    this.doctorSelectRef = React.createRef();

    this.getTrProps = (state, rowInfo, instance) => {
      if (rowInfo) {
        return {
          style: {
            background: rowInfo.original.isSameDaySlotRequested
              ? '#ECD9BD'
              : '',
          },
        };
      }
      return {};
    };
  }

  getSponsors = () => {
    return fetchAllSponsors()
      .then((response) => {
        const sponsorsList = response.data.map((sponsor) => {
          return {
            value: sponsor.sponsorId,
            label: sponsor.sponsorName,
          };
        });
        console.log(sponsorsList, 'Response from server');
        this.setState({
          sponsorList: sponsorsList,
        });
      })
      .catch(() => {
        this.setState({
          sponsorList: [],
        });
      });
  };

  getAppointmentPreference = () => {
    return fetchAppointmentPreference()
      .then((response) => {
        const appointmentPreferenceList = response.data.map((option) => {
          return {
            value: option.id,
            label: option.text,
          };
        });
        this.setState({
          appointmentPreferenceList: appointmentPreferenceList,
        });
      })
      .catch(() => {
        this.setState({
          appointmentPreferenceList: [],
        });
      });
  };

  componentDidMount() {
    const { location, prebooked, providerClaims } = this.props;
    this.getSponsors();
    this.getAppointmentPreference();
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get('sourceId')) {
        this.showDetails(searchParams.get('sourceId'));
      }
    }
    if (prebooked) {
      this.setState({
        preBooked: true,
      });
    }

    if(providerClaims) {
      this.setState({
        providerClaims: true
      })
    }
  }

  // componentDidUpdate(prevProps, prevState) {

  //   if(this.props.match && this.props.match.params && this.props.dispatch){
  //   const {match} = this.props
  //   const requestId = match.params.reqId;
  //   console.log(requestId,'request id for modal CDU')
  //   this.props.dispatch(showRequestModal(requestId))
  //   }

  // }

  changeProcessingDate = (req) => {
    console.log({ req });

    this.setState({
      changeProcessingDateModal: {
        isOpen: true,
        requestId: req.requestId,
        processingDate: req.processingDate,
      },
    });
  };

  closeProcessingDateModal = () => {
    this.setState({
      changeProcessingDateModal: {
        isOpen: false,
        requestId: null,
        processingDate: null,
      },
    });
    this.fetchTableData();
  };

  handleChangeForCallReason = (event) => {
    const { value } = event.target;
    this.setState({ selectedCallReason: value, colorGreading: true });
  };

  toggleLegend() {
    this.setState({
      legendOpen: !this.state.legendOpen,
    });
  }

  toggleCommentTooltip(reqId) {
    this.setState({
      tooltipOpen: this.state.tooltipOpen === reqId ? false : reqId,
    });
  }

  openCommunications(data) {
    // console.log(data, 'openCommunications');
    this.setState({
      openComms: true,
      commsData: data,
    });
  }

  openEditUserPayment = (requestId) => {
    // console.log(requestId, '[openEditUserPayment]');
    this.setState({
      showUserPaymentModal: true,
      showUserPaymentModalRequestId: requestId,
    });
  };

  openClaimRequestButton = (requestId) => {
    this.setState({
      showClaimRequestModal: true,
      showClaimRequestModalRequestId: requestId,
    });
  };

  closeEditUserPayment = () => {
    this.setState({
      showUserPaymentModal: false,
      showUserPaymentModalRequestId: null,
    });
  };

  closeCommunications = () => {
    // console.log('closeCommunications');
    this.setState({
      openComms: false,
    });
  };

  filterMethod = (filter, row) => {
    // Pivoted rows won't contain the column.
    //  If that's the case, we set the to true (allowing us to only filter on the current column)
    const rowValue = row[filter.id];
    if (!rowValue) {
      return true;
    }

    const filterValue = filter.value.filterValue || '';
    const filterType = filter.value.filterType;
    switch (filterType) {
      case 'contains':
        return rowValue.indexOf(filterValue) > -1;
      case 'starts-with':
        return rowValue.startsWith(filterValue);
      case 'ends-with':
        return rowValue.endsWith(filterValue);
      case 'matches':
        return rowValue === filterValue;
      case 'greater-than':
        return rowValue > filterValue;
      case 'less-than':
        return rowValue < filterValue;
      default:
        return true;
    }
  };

  openRequestChatModal = (requestId) => {
    if (!requestId) {
      return;
    }
    this.props.dispatch(showInternalComments(requestId));
  };

  submitUserAcknowledgeCall = (requestId) => {
    if (!requestId) {
      return;
    }
    //const res=callUserRequest(requestId, 'userAcknowledge',this.state.selectedCallReason).then((res)=> res.data);
    return callUserRequest(
      requestId,
      'userAcknowledge',
      this.state.selectedCallReason
    ).then((res) => {
      //this.fetchTableData();
      if (res?.message == 'success') {
        this.state.selectedCallReason = null;
      }
    });
  };

  setSponsorFilter = (sponsorId) => {
    this.setState(
      {
        sponsorFilter: sponsorId,
      },
      this.fetchTableData
    );
  };

  handleSponsor = (sponsorObj) => {
    this.setState(
      {
        sponsorFilter: sponsorObj.value,
      },
      this.fetchTableData
    );
  };

  handleAppointmentPreference = (appointmentPreferenceObj) => {
    this.setState(
      {
        appointmentPreferenceFilter: appointmentPreferenceObj.value,
      },
      this.fetchTableData
    );
  };

  submitDoctorReminded = (requestId) => {
    if (!requestId) return;
    return callDoctorRequest(requestId, 'doctorReminder').then(
      this.fetchTableData
    );
  };

  submitUserCall = (requestId) => {
    if (!requestId) {
      return;
    }
    return callUserRequest(requestId, 'user').then(this.fetchTableData);
  };

  submitDoctorCall = (requestId) => {
    if (!requestId) {
      return;
    }
    return callDoctorRequest(requestId).then(this.fetchTableData);
  };

  submitUserReminded = (requestId) => {
    if (!requestId) {
      return;
    }
    return callUserRequest(requestId, 'userReminder').then(this.fetchTableData);
  };

  showDetails = (requestId, requestDetails = {}) => {
    if (!requestId) {
      return;
    }
    this.setState({
      currentRequestDetails: requestDetails,
    });
    this.props.dispatch(showRequestModal(requestId));
  };

  editDoctorSlots = (doctorId) => {
    console.log('Check doctor Id here for editDoctorSlots', doctorId);
    if (!doctorId) {
      return;
    }
    this.props.dispatch(showDoctorSlotsEditModal(doctorId));
  };

  confirmConvertToReimbursementRequest = (request) => () => {
    this.setState({
      confirmModal: {
        isOpen: true,
        header: 'Convert to Reimbursement Request',
        body: (
          <div className="container">
            <div className="col-12">
              <FormGroup check>
                <Label for="convertReason">
                  Specify a reason to convert this request to reimbursement
                </Label>
                <input type="text" name="convertReason" id="convertReason" />
              </FormGroup>
            </div>
          </div>
        ),
        onSubmit: this.convertToReimbursementRequest,
        requestId: request.requestId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  convertToReimbursementRequest = (requestId) => {
    const { confirmModal } = this.state;
    const reasonInput = document.querySelector('#convertReason').value;
    if (!reasonInput) {
      toast.error(
        'Please specify a valid reason to convert this request to reimbursement'
      );
      return Promise.resolve();
    }
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    return convertConsultToReimbursement(requestId, reasonInput)
      .then((res) => {
        console.log(res);
        if (res.message === 'success') {
          toast.success('Converted to reimbursement successfully!');
          this.fetchTableData();
        } else {
          toast.error(
            res.errorMessage || 'Failed to convert to reimbursement request'
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err.errorMessage || 'Failed to convert to reimbursement request'
        );
      })
      .finally(() => {
        this.setState({
          confirmModal: {
            buttonLoading: false,
          },
        });
      });
  };
  // Confirm Modal
  confirmOpenModal = (requestId, priceApprovalStatus, requestData) => {
    if (priceApprovalStatus === 'Pending') {
      toast.error(
        'There is an active Price change request for this doctor. Please connect with network team. Until then you cannot confirm this appointment.'
      );
      return;
    }
    this.setState({
      confirmModalIsOpen: true,
      requestId: requestId,
      requestDataForConfirm: requestData,
    });
  };

  confirmCloseModal = () => {
    this.setState({ confirmModalIsOpen: false });
  };

  submitConfirmModal = (
    dateKey,
    slot,
    slotType = 'fixed',
    startAppointmentTime,
    endAppointmentTime
  ) => {
    const { requestId } = this.state;
    const timeRange =
      startAppointmentTime && endAppointmentTime && slotType == 'walk-in'
        ? `${startAppointmentTime}-${endAppointmentTime}`
        : null;
    if (!requestId || !slot || !dateKey) {
      console.error({ requestId, dateKey, slot }, 'Params missing');
      return;
    }
    if (
      slotType == 'walk-in' &&
      !(startAppointmentTime || endAppointmentTime)
    ) {
      console.error(
        { startAppointmentTime, endAppointmentTime },
        'Params missing'
      );
      return;
    }
    return confirmRequest(
      requestId,
      dateKey,
      slot,
      this.state.centerRequestStatus,
      slotType,
      timeRange
    )
      .then((response) => {
        // Handle success response

        if (
          response &&
          response.data &&
          response.data.message === 'serverError'
        ) {
          toast.error(response.data.errorMessage);
        } else {
          toast.success('Confirm Request Success');
        }
      })
      .catch((err) => {
        // Error Handle
        if (err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error('Confirm Request Failed. Please try again.');
        }
      })
      .finally(() => {
        // Handle Final (mainly for spinners)
      });
    // //  else {
    //   console.log(this.state.centerRequestStatus,'center request')
    //    return confirmDoctorAppointmentRequest(requestId,dateKey,slot)
    //    .then((res)=>{
    //     if(res.message === 'success')
    //     toast.success('Confirm Request Success');
    //    })
    //    .catch((err) => {
    //     // Error Handle
    //     toast.error('Confirm Request fail Please try again');
    //   })
    //   .finally(() => {
    //     // Handle Final (mainly for spinners)
    //   });
  };

  // Cancel Modal
  cancelOpenModal = (requestId, priceApprovalStatus) => {
    if (priceApprovalStatus === 'Pending') {
      toast.error(
        'There is an active Price change request for this doctor. Please connect with network team. Until then you cannot cancel this appointment.'
      );
      return;
    }
    fetchCancelledReasons(requestId)
      .then((response) => {
        this.setState({
          cancelledReasons: response.cancelledReasons,
        });
      })
      .catch(() => {
        this.setState({
          cancelledReasons: [],
        });
      });
    this.setState({ cancelModalIsOpen: true, requestId });
  };

  cancelCloseModal = () => {
    this.setState({ cancelModalIsOpen: false });
  };

  cancelNonPayableCloseModal = () => {
    this.setState({ nonPayableClaimsOpenModal : false});
  }

  cancelPayableCloseModal = () => {
    this.setState({ payableClaimsOpenModal : false});
  }

  submitCancelModal = (reason, remarks, cancellationFee, subCancelReason, userComms) => {
    const { requestId } = this.state;
    if (!requestId || !reason) {
      console.error({ requestId, reason }, 'No request id present');
      toast.error('Select a reason to continue');
      return;
    }
    return cancelRequest(
      requestId,
      reason,
      remarks,
      cancellationFee,
      subCancelReason,
      userComms
    )
      .then((response) => {
        // Handle success response
        if (
          response &&
          response.data &&
          response.data.message == 'serverError'
        ) {
          toast.error(response.data.errorMessage);
        } else {
          toast.success('Cancel Request Success');
        }
      })
      .catch((err) => {
        // Error Handle
        if (err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error('Cancel Request Failed. Please try again');
        }
      })
      .finally(() => {
        // Handle Final (mainly for spinners)
      });
  };

  submitProviderClaimsModal = (reason, remarks, providerClaimsDoc, providerClaimsType) => {
    const { requestId } = this.state;
    if (!requestId || !reason || !providerClaimsDoc || !providerClaimsType) {
      console.error({ requestId, reason }, 'No request id present');
      toast.error('Select a reason to continue');
      return;
    }
    return providerClaimsRequest(
      requestId,
      reason,
      remarks,
      providerClaimsDoc,
      providerClaimsType
    )
      .then((response) => {
        // Handle success response
        toast.success('Provider Claims Settlement Request Success');
      })
      .catch((err) => {
        // Error Handle
        toast.error('Provider Claims Settlement Request fail Please try again');
      })
      .finally(() => {
        // Handle Final (mainly for spinners)
      });
  };

  onFetchData = (state, instance) => {
    let orderBy, orderDir;
    if (state.sorted.length > 0) {
      orderBy = state.sorted[0].id;
      orderDir = state.sorted[0].desc ? 'desc' : 'asc';
    } else {
      orderBy = 1;
      orderDir = 'desc';
    }
    console.log(state, 'State on fetch data');
    let claimedByFilter = '';
    state.filtered.forEach((f) => {
      if (f.id === 'claimedUserName') {
        claimedByFilter = f.value;
      }
    });
    this.setState(
      {
        pageSize: state.pageSize,
        page: state.page,
        orderBy,
        orderDir,
        claimedByFilter,
      },
      this.fetchTableData
    );
  };

  fetchTableData = (buttonStatus) => {
    this.setState({
      loading: true,
    });
    let filterApplied = false;
    const params = {
      draw: this.state.pageSize,
      page: this.state.page,
      orderBy: this.state.orderBy,
      orderDir: this.state.orderDir,
      statusFilter: this.state.statusFilter,
      search: this.state.search || null,
      sponsorFilter: this.state.sponsorFilter,
      appointmentPreferenceFilter: this.state.appointmentPreferenceFilter,
      claimedByFilter: this.state.claimedByFilter,
    };

    if (
      params.search ||
      params.sponsorFilter ||
      params.claimedByFilter ||
      params.statusFilter ||
      params.appointmentPreferenceFilter
    ) {
      filterApplied = true;
    }
    if (this.state.onlyVipUsers && this.state.onlyVipUsers === true) {
      filterApplied = true;
      params.onlyVipUsers = true;
    }

    if (this.state.delayedResponse && this.state.delayedResponse === true) {
      filterApplied = true;
      params.delayedResponse = true;
    }

    if (this.state.segregation !== null) {
      filterApplied = true;
      params.segregation = this.state.segregation;
    }

    if (
      this.state.processingDateFilter.startDate &&
      this.state.processingDateFilter.endDate
    ) {
      filterApplied = true;
      params.processingDateFilterStartDate = moment(
        this.state.processingDateFilter.startDate
      ).format('YYYY-MM-DD');
      params.processingDateFilterEndDate = moment(
        this.state.processingDateFilter.endDate
      ).format('YYYY-MM-DD');
    }

    if (
      this.state.appointmentDateFilter.startDate &&
      this.state.appointmentDateFilter.endDate
    ) {
      filterApplied = true;
      params.appointmentDateFilterStartDate = moment(
        this.state.appointmentDateFilter.startDate
      ).format('YYYY-MM-DD');
      params.appointmentDateFilterEndDate = moment(
        this.state.appointmentDateFilter.endDate
      ).format('YYYY-MM-DD');
    }

    if (this.state.requestIdFilter) {
      filterApplied = true;
      params.requestId = this.state.requestIdFilter;
    }

    if (this.state.networkCenterNameFilter) {
      filterApplied = true;
      params.networkCenterNameFilter = this.state.networkCenterNameFilter;
    }

    if (this.state.doctorNameFilter) {
      filterApplied = true;
      params.doctorNameFilter = this.state.doctorNameFilter;
    }

    if (this.state.patientNameFilter) {
      filterApplied = true;
      params.patientNameFilter = this.state.patientNameFilter;
    }
    if (this.state.ackFilter) {
      filterApplied = true;
      params.acknowledgementCallFilter = this.state.ackFilter;
    }

    if (this.state.inHouseFilter) {
      filterApplied = true;
      params.inHouseFilter = this.state.inHouseFilter;
    }

    if (this.state.payoutFilter) {
      filterApplied = true;
      params.payoutFilter = this.state.payoutFilter;
    }

    if (this.state.pendingFollowUpFilter) {
      filterApplied = true;
      params.pendingFollowUpFilter = this.state.pendingFollowUpFilter;
    }

    if (this.state.isRescheduledFilter) {
      filterApplied = true;
      params.isRescheduledFilter = this.state.isRescheduledFilter;
    }

    if (this.state.isReconfirmationRequiredFilter) {
      filterApplied = true;
      params.isReconfirmationRequiredFilter =
        this.state.isReconfirmationRequiredFilter;
    }

    if (this.state.isReconfirmationFilter) {
      filterApplied = true;
      params.isReconfirmationFilter = this.state.isReconfirmationFilter;
    }

    if (this.state.isPendingCallbackFilter) {
      filterApplied = true;
      params.isPendingCallbackFilter = this.state.isPendingCallbackFilter;
    }

    if (this.state.isEmergencyCalls) {
      filterApplied = true;
      params.isEmergencyCalls = this.state.isEmergencyCalls;
    }

    params.onboardingStatusFilter = this.state.onboardingStatusFilter;
    params.networkSourceFilter = this.state.networkSourceFilter;
    params.paymentCycleFilter = this.state.paymentCycleFilter;

    if (
      params.onboardingStatusFilter ||
      params.networkSourceFilter ||
      params.paymentCycleFilter
    ) {
      filterApplied = true;
    }

    if (!filterApplied && !this.state.requestDateDefaultFilterApplied) {
      this.setState({
        requestDateFilter: DefaultRequestDateFilter(),
        requestDateDefaultFilterApplied: true,
      });
      console.log('Request Date default filter applied');
    } else if (filterApplied && this.state.requestDateDefaultFilterApplied) {
      this.setState({
        requestDateFilter: {
          startDate: null,
          endDate: null,
        },
        requestDateDefaultFilterApplied: false,
      });
    }

    if (
      this.state.requestDateFilter.startDate &&
      this.state.requestDateFilter.endDate
    ) {
      params.requestDateFilterStartDate = moment(
        this.state.requestDateFilter.startDate
      ).format('YYYY-MM-DD');
      params.requestDateFilterEndDate = moment(
        this.state.requestDateFilter.endDate
      ).format('YYYY-MM-DD');
    }
    console.log(this.state.preBooked,this.state.providerClaims, 'prebooked123');
    if (this.state.preBooked) {
      params.statusFilter = 'prebooked_appointment';
    }

    if(this.state.providerClaims && params.statusFilter !== 'prescriptionRejected' &&  params.statusFilter !== 'prescriptionPending') {
      params.statusFilter = 'provider_claims_settlement';
    }

    console.log('params', params);
    console.log(
      'state.requestDateDefaultFilterApplied',
      this.state.requestDateDefaultFilterApplied
    );
    return getAllOfflineRequests(params)
      .then((responseBody) => {
        if (
          this.state.currentButtonStatus &&
          buttonStatus == this.state.currentButtonStatus
        ) {
          this.setState({
            data: responseBody.result,
            loading: false,
          });
        }
        if (!this.state.currentButtonStatus || !buttonStatus) {
          this.setState({
            data: responseBody.result,
            loading: false,
          });
        }
      })
      .then(() => {
        if (this.state.requestDateDefaultFilterApplied) {
          params.requestDateFilterStartDate = null;
          params.requestDateFilterEndDate = null;
        }
        return getAllOfflineRequestsCount(params);
      })
      .then((responseBody) => {
        this.setState({
          pages: Math.ceil(responseBody.totalHits / this.state.pageSize),
          totalHits: responseBody.totalHits || 0,
        });
      })
      .catch((err) => {
        console.error({ err, params });
      });
  };

  closeDetailModal = () => {
    this.setState({
      currentRequestDetails: {},
    });
    this.props.dispatch(hideRequestModal());
  };

  closeDoctorSlotsEditModal = () => {
    this.setState({
      showEditDoctorSlots: false,
    });
    this.props.dispatch(hideDoctorSlotsEditModal());
  };
  closeRequestChatModal = () => {
    this.props.dispatch(hideInternalComments());
  };

  handlePlaceRequestSubmit = (
    doctorId,
    centerId,
    userId,
    relativeId,
    dateKey,
    slotId,
    policyNumber,
    dependant
  ) => {
    return placeRequest(
      doctorId,
      centerId,
      userId,
      relativeId,
      dateKey,
      slotId,
      policyNumber,
      dependant
    ).then((response) => {
      if (response.message === 'success') {
        toast.success('Request Placed Success');
        return this.fetchTableData().then(() => true);
      } else {
        toast.error(response.errorMessage);
        return false;
      }
    });
  };

  onChangeOnboardingStatusFilter = (value) => {
    this.setState(
      {
        onboardingStatusFilter: value,
      },
      this.fetchTableData
    );
  };

  onChangeNetworkSourceStatusFilter = (value) => {
    this.setState(
      {
        paymentCycleFilter: value,
      },
      this.fetchTableData
    );
  };

  setRequestIdFilter = (event) => {
    const { value } = event.target;
    this.setState(
      {
        requestIdFilter: value,
      },
      this.fetchTableData
    );
  };

  setNetworkCenterNameFilter = (event) => {
    const { value } = event.target;
    this.setState({
      networkCenterNameFilter: value,
    });
  };
  setDoctorNameFilter = (event) => {
    const { value } = event.target;
    this.setState({
      doctorNameFilter: value,
    });
  };

  setPatientNameFilter = (event) => {
    const { value } = event.target;
    this.setState({
      patientNameFilter: value,
    });
  };

  setAckButtonConfirmModal = (requestId) => {
    this.setState({
      isCallModalOpen: true,
      confirmModal: {
        isOpen: true,
        header: 'Call User?',
        body: 'Are you sure you want to call user?',
        requestId,
        onSubmit: this.submitUserAcknowledgeCall,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
          this.setState({ isCallModalOpen: false });
          this.setState({ selectedCallReason: null });
        },
      },
    });
  };

  setInHouseFilter = (status) => {
    this.setState(
      {
        inHouseFilter: status,
      },
      this.fetchTableData
    );
  };

  setPayoutFilter = (payoutStatus) => {
    this.setState(
      {
        payoutFilter: payoutStatus,
      },
      this.fetchTableData
    );
  };

  changePendingFollowUpFitler = (pendingFollowUpStatus) => {
    this.setState(
      {
        pendingFollowUpFilter: pendingFollowUpStatus,
      },
      this.fetchTableData
    );
  };

  setCallUserButtonConfirmModal = (requestId) => {
    this.setState({
      confirmModal: {
        isOpen: true,
        header: 'Call User?',
        body: 'Are you sure you want to call user?',
        requestId,
        onSubmit: this.submitUserCall,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
      },
    });
  };

  setCallDoctorButtonConfirmModal = (requestId) => {
    this.setState({
      confirmModal: {
        isOpen: true,
        header: 'Call Doctor?',
        body: 'Are you sure you want to call doctor?',
        requestId,
        onSubmit: this.submitDoctorCall,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
      },
    });
  };

  setUserRemindedConfirmModal = (requestId) => {
    this.setState({
      confirmModal: {
        isOpen: true,
        header: 'Call User?',
        body: 'Are you sure you want to call for reminding him/her?',
        requestId,
        onSubmit: this.submitUserReminded,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
      },
    });
  };

  setDoctorRemindedConfirmModal = (requestId) => {
    this.setState({
      confirmModal: {
        isOpen: true,
        header: 'Call Doctor?',
        body: 'Are you sure you call doctor for reminding him/her?',
        requestId,
        onSubmit: this.submitDoctorReminded,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
      },
    });
  };

  submitConfirmModalGen = () => {
    const { onSubmit, requestId } = this.state.confirmModal;
    return onSubmit(requestId).then(() => {
      this.setState({ confirmModal: { isOpen: false } });
      this.setState({ isCallModalOpen: false });
    });
  };

  onClaimRequestModalClose = () => {
    this.setState(
      {
        showClaimRequestModal: false,
        showClaimRequestModalRequestId: null,
      },
      this.fetchTableData
    );
  };

  onAction = () => {
    this.fetchTableData();
  };

  changeStatusFilter = (value) => {
    this.setState({ statusFilter: value }, this.fetchTableData);
  };

  handleChangeCheckbox = (e) => {
    let allCheckedState = this.state.allChecked;
    if (e.target.checked) {
      this.setState({
        allChecked: [...this.state.allChecked, parseInt(e.target.value)],
      });
    } else {
      this.setState({
        allChecked: allCheckedState.filter((item) => item != e.target.value),
        selectAllChecked: false,
      });
    }
  };

  handleAllChangeCheckbox = (e) => {
    if (e.target.checked) {
      this.setState({
        selectAllChecked: !this.state.selectAllChecked,
        allChecked: this.state.data.map((res) => {
          return res.requestId;
        }),
      });
    } else {
      this.setState({
        selectAllChecked: !this.state.selectAllChecked,
        allChecked: [],
      });
    }
  };

  changeAckFitler = (value) => {
    this.setState({ ackFilter: value }, this.fetchTableData);
  };

  changeRequestDateFilter = (startDate, endDate) => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      this.setState(
        {
          requestDateFilter: {
            ...this.state.requestDateFilter,
            startDate,
            endDate,
          },
        },
        this.fetchTableData
      );
    } else {
      this.setState({
        requestDateFilter: {
          ...this.state.requestDateFilter,
          startDate,
          endDate,
        },
      });
    }
  };

  changeProcessingDateFilter = (startDate, endDate) => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      this.setState(
        {
          processingDateFilter: {
            ...this.state.processingDateFilter,
            startDate,
            endDate,
          },
        },
        this.fetchTableData
      );
    } else {
      this.setState({
        processingDateFilter: {
          ...this.state.processingDateFilter,
          startDate,
          endDate,
        },
      });
    }
  };

  changeAppointmentDateFilter = (startDate, endDate) => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      this.setState(
        {
          appointmentDateFilter: {
            ...this.state.appointmentDateFilter,
            startDate,
            endDate,
          },
        },
        this.fetchTableData
      );
    } else {
      this.setState({
        appointmentDateFilter: {
          ...this.state.appointmentDateFilter,
          startDate,
          endDate,
        },
      });
    }
  };

  clearFilters = () => {
    this.setState(
      {
        appointmentDateFilter: {
          startDate: null,
          endDate: null,
        },
        requestDateFilter: {
          startDate: null,
          endDate: null,
        },
        statusFilter: null,
        ackFilter: null,
        inHouseFilter: null,
        payoutFilter: null,
        pendingFollowUpFilter: null,
        sponsorFilter: null,
        appointmentPreferenceFilter: null,
        requestIdFilter: null,
        networkCenterNameFilter: null,
        doctorNameFilter: null,
        patientNameFilter: null,
        onlyVipUsers: null,
        delayedResponse: null,
        isRescheduledFilter: null,
        isReconfirmationRequiredFilter: null,
        isReconfirmationFilter: null,
        isPendingCallbackFilter: null,
        isEmergencyCalls: null,
        pendingFollowUpFilter: null,
      },
      this.fetchTableData
    );
  };

  setSegregationFilter = (sg) => {
    const { segregation } = this.state;
    if (segregation === sg) {
      return;
    }
    this.setState({
      requestDateFilter: {
        startDate: null,
        endDate: null,
      },
    });
    let status = null;
    let currentStatus;
    let cols;
    if (sg === 1) {
      currentStatus = 'confirmed';
      this.setState({
        currentButtonStatus: 'confirmed',
        requestDateDefaultFilterApplied: false,
        requestDateFilter: DefaultRequestDateFilter(),
      });
      cols = this.tableAllColumns(this.state.preBooked, 'confirmed');
    } else if (sg === 0) {
      currentStatus = 'pending';
      this.setState({
        currentButtonStatus: 'pending',
        requestDateDefaultFilterApplied: false,
        requestDateFilter: DefaultRequestDateFilter(),
      });
      cols = this.tableAllColumns(this.state.preBooked, 'pending');
    } else if (sg === 2) {
      currentStatus = 'reimbursement';
      this.setState({
        currentButtonStatus: 'reimbursement',
      });
      cols = this.tableAllColumns(this.state.preBooked, 'reimbursement');
    } else if (sg === 3) {
      currentStatus = 'rescheduled';
      this.setState({
        currentButtonStatus: 'rescheduled',
      });
      cols = this.tableAllColumns(this.state.preBooked, 'rescheduled');
    } else if (sg === 4) {
      currentStatus = 'reconfirmation-required';
      this.setState({
        currentButtonStatus: 'reconfirmation-required',
      });
      cols = this.tableAllColumns(
        this.state.preBooked,
        'reconfirmation-required'
      );
    } else if (sg === 5) {
      currentStatus = 'reconfirmation';
      this.setState({
        currentButtonStatus: 'reconfirmation',
      });
      cols = this.tableAllColumns(this.state.preBooked, 'reconfirmation');
    } else if (sg === 6) {
      currentStatus = 'pending-callback';
      this.setState({
        currentButtonStatus: 'pending-callback',
      });
      cols = this.tableAllColumns(this.state.preBooked, 'pending-callback');
    } else if (sg === 7) {
      currentStatus = 'processing';
      this.setState({
        currentButtonStatus: 'processing',
      });
      cols = this.tableAllColumns(this.state.preBooked, 'processing');
    } else if (sg === 8) {
      currentStatus = 'emergency-calls';
      this.setState({
        currentButtonStatus: 'emergency-calls',
      });
      cols = this.tableAllColumns(this.state.preBooked, 'emergency-calls');
    } else {
      currentStatus = '';
      this.setState({
        currentButtonStatus: '',
        requestDateDefaultFilterApplied: false,
        requestDateFilter: DefaultRequestDateFilter(),
      });
      cols = this.tableAllColumns(this.state.preBooked);
    }
    this.setState(
      {
        segregation: sg,
        tableColumns: cols,
        appointmentDateFilter: {
          startDate: null,
          endDate: null,
        },
        statusFilter: status,
        ackFilter: null,
        inHouseFilter: null,
        payoutFilter: null,
        pendingFollowUpFilter: null,
        sponsorFilter: null,
        appointmentPreferenceFilter: null,
        requestIdFilter: null,
        networkCenterNameFilter: null,
        doctorNameFilter: null,
        patientNameFilter: null,
        onlyVipUsers: null,
        delayedResponse: null,
        isRescheduledFilter: sg === 3 ? 1 : null,
        isReconfirmationRequiredFilter: sg === 4 ? 1 : null,
        isReconfirmationFilter: sg === 5 ? 1 : null,
        isPendingCallbackFilter: sg === 6 ? 1 : null,
        isEmergencyCalls: sg === 8 ? 1 : null,

        search: '',
      },
      () => this.fetchTableData(currentStatus)
    );
  };

  setVipUsersFilter = () => {
    this.setState(
      {
        onlyVipUsers: !this.state.onlyVipUsers,
        delayedResponse: false,
        currentButtonStatus: 'vip-user',
      },
      () => this.fetchTableData('vip-user')
    );
  };

  delayedResponseFilter = () => {
    this.setState(
      {
        delayedResponse: true,
        onlyVipUsers: false,
        requestDateFilter: {
          startDate: null,
          endDate: null,
        },
        appointmentDateFilter: {
          startDate: null,
          endDate: null,
        },
        currentButtonStatus: 'delayed-req',
      },
      () => this.fetchTableData('delayed-req')
    );
  };

  bulkAssigneRequest = () => {
    this.setState({
      addAssigneModalOpen: true,
      addAssigneFlag: 'addBulkAssigne',
    });
  };

  handleAddDoctorDetail = () => {
    this.setState({ addDoctorDetailModalOpen: true });
  };

  toggleRequestReconfirmationModal = (requestId) => {
    const { requestReconfirmationModalOpen } = this.state;
    console.log('toggleShiftToPendingModal', requestId);
    this.setState({
      requestReconfirmationModalOpen: !requestReconfirmationModalOpen,
      requestReconfirmationRequestId: requestId,
    });
  };

  requestReconfirmationForConsultation = (requestId) => {
    this.setState({
      loading: true,
    });
    console.log('requestReconfirmationForConsultation', requestId);
    return requestReconfirmationForOfflineConsult(requestId)
      .then((resp) => {
        if (resp.message === 'success') {
          toast.success('Successfully Requested For Reconfirmation');
          // this.onFetchData();
        }
        toast.error(resp.errorMessage);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
        this.toggleRequestReconfirmationModal();
      });
  };

  openSuggestions = (details) => {
    console.log({ details }, 'openSuggestions');
    this.setState({
      showSuggestions: true,
      suggestionsDetails: details,
    });
  };

  toggleShiftToPendingModal = (requestId) => {
    const { shiftToPendingModalOpen } = this.state;
    console.log('Shift tp pending', requestId);
    this.setState({
      shiftToPendingModalOpen: !shiftToPendingModalOpen,
      shiftToPendingRequestId: requestId,
    });
  };

  togglePriceChangeRequest = (requestIdData) => {
    const { priceChangeRequetsModal } = this.state;
    console.log('Price Change Request', requestIdData);
    this.setState({
      priceChangeRequetsModal: !priceChangeRequetsModal,
      requestIdDataForPriceChange: requestIdData,
      newPrice: '',
      priceChangeReason: '',
      inputReason: '',
    });
  };

  handlePriceChangeRequest = (price) => {
    console.log('Price Change Input', price);
    this.setState({
      newPrice: price,
    });
  };

  sendPriceChangeRequest = () => {
    this.setState({
      loading: true,
    });
    const { requestIdDataForPriceChange, newPrice } = this.state;
    console.log(requestIdDataForPriceChange, 'requestIdDataForPriceChange');
    const params = {
      requestId: requestIdDataForPriceChange.requestId,
      centerId: requestIdDataForPriceChange.networkCenterId,
      doctorId: requestIdDataForPriceChange.doctorId,
      newPrice: newPrice,
      doctorName: requestIdDataForPriceChange.doctorName,
      centerName: requestIdDataForPriceChange.networkCenterName,
      fullfillmentType:
        requestIdDataForPriceChange.fullfillmentType || 'center',
      onBoardingStatus: requestIdDataForPriceChange.onBoardingStatus,
      priceChangeReason:
        this.state.priceChangeReason == 'Other'
          ? this.state.inputReason
          : this.state.priceChangeReason,
    };
    console.log('Price Change Request', params);
    return sendPriceChangeRequestAPI(params)
      .then((resp) => {
        if (resp.message === 'success') {
          toast.success('Successfully Requested For Price Change');
          // this.onFetchData();
        }
        toast.error(resp.errorMessage);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error('Request Failed! Please try again');
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
        this.togglePriceChangeRequest();
      });
  };

  toggleShiftToReimbursementModal = (requestId) => {
    const { shiftReimbursementModalOpen } = this.state;
    console.log('Shift to Reimbursement', requestId);
    this.setState({
      shiftReimbursementModalOpen: !shiftReimbursementModalOpen,
      shiftToReimbursementReqId: requestId,
    });
  };

  toggleAddAssigneModal = (requestId) => {
    const { addAssigneModalOpen } = this.state;
    this.setState({
      addAssigneModalOpen: !addAssigneModalOpen,
      addAssigneReqId: requestId,
      addAssigneFlag: 'addAssigne',
    });
  };

  toggleAddDoctorDetailModal = () => {
    const { addDoctorDetailModalOpen } = this.state;
    this.setState({
      addDoctorDetailModalOpen: !addDoctorDetailModalOpen,
    });
  };

  openDoctorSelectionModal = async (centerId, vertical, requestId) => {
    console.log(
      centerId,
      vertical,
      requestId,
      'center id for doctor selection'
    );
    const { doctorSelectionModal } = this.state;
    const params = { centerId, verticalId: vertical };
    const doctorList = await getDoctorSelectionList(params);
    let doctors = [];
    doctorList.result.map((ele) => {
      doctors.push({ label: ele.doctorName, value: ele.doctorId });
    });
    this.setState({
      doctorSelectionModal: !doctorSelectionModal,
      doctorSelectionCenterId: centerId,
      doctorSelectionVertical: vertical,
      doctorSelectionRequestId: requestId,
      defaultDoctorSearch: doctors,
    });
  };

  getDoctorSelectionList = async (value) => {
    const params = {
      centerId: this.state.doctorSelectionCenterId,
      verticalId: this.state.doctorSelectionVertical,
      q: value,
    };
    const doctorList = await getDoctorSelectionList(params);
    console.log(doctorList, 'doctor list for selection');
    return doctorList.result.map((ele) => {
      return {
        label: ele.doctorName,
        value: ele.doctorId,
      };
    });
  };

  confirmDoctorSelection = async () => {
    console.log(
      this.doctorSelectRef.current.select.state.value.value,
      'confirm doctor selection'
    );
    const body = {
      doctorId: this.doctorSelectRef.current.select.state.value.value,
      requestId: this.state.doctorSelectionRequestId,
      centerId: this.state.doctorSelectionCenterId,
    };
    return appointDoctorRequest(body).then((res) => {
      if (res.message === 'success') {
        this.setState({
          doctorSelectionModal: false,
        });
        toast.success('Successfully appointed doctor');
        // this.onFetchData();
        this.setState(
          {
            appointmentDateFilter: {
              startDate: null,
              endDate: null,
            },
            requestDateFilter: {
              startDate: null,
              endDate: null,
            },
            ackFilter: null,
            inHouseFilter: null,
            payoutFilter: null,
            pendingFollowUpFilter: null,
            sponsorFilter: null,
            appointmentPreferenceFilter: null,
            requestIdFilter: this.state.doctorSelectionRequestId,
            networkCenterNameFilter: null,
            doctorNameFilter: null,
            patientNameFilter: null,
            onlyVipUsers: null,
            delayedResponse: null,
            search: '',
          },
          this.fetchTableData
        );
      } else {
        toast.error('Server error');
      }
    });
  };

  shiftAppointmentToPending = (requestId) => {
    this.setState({
      loading: true,
    });
    console.log(`Change Status to pending ${requestId}`);
    shiftOfflineConsultationStatusToPending(requestId)
      .then((res) => {
        // Fetch consults
        if (res.message === 'success') {
          toast.success('Success');
          this.fetchTableData();
        } else {
          toast.error(res.errorMessage || 'Failed to Shift to Pending');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error(err.errorMessage || 'Failed to Shift to Pending');
        }
      })
      .finally(() => {
        this.toggleShiftToPendingModal(this.state.shiftToPendingRequestId);
        this.setState({
          loading: false,
        });
      });
  };

  shiftAppointmentToReimbursement = (requestId) => {
    this.setState({
      loading: true,
    });
    console.log(`Shifting to reimbursement ${requestId}`);
    shiftOfflineConsultationToReimbursement(requestId)
      .then((res) => {
        // Fetch consults
        if (res.message === 'success') {
          toast.success('Success');
          this.fetchTableData();
        } else {
          toast.error(res.errorMessage || 'Failed to Shift to reimbursement');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.errorMessage) {
          toast.error(err.response.data.errorMessage);
        } else {
          toast.error(err.errorMessage || 'Failed to Shift to reimbursement');
        }
      })
      .finally(() => {
        this.toggleShiftToReimbursementModal(
          this.state.shiftToReimbursementReqId
        );
        this.setState({
          loading: false,
        });
      });
  };

  addAssigneToAppointment = (requestId) => {
    this.setState({
      loading: true,
    });
    addAssigneToAppointmentReq(requestId)
      .then((res) => {
        // Fetch consults
        if (res.message === 'success') {
          toast.success('Success');
          this.fetchTableData();
        } else {
          toast.error(res.errorMessage || 'Failed to add assigne');
        }
      })
      .catch((err) => {
        toast.error(err.errorMessage || 'Failed to add assigne');
      })
      .finally(() => {
        this.toggleAddAssigneModal(this.state.addAssigneReqId);
        this.setState({
          loading: false,
        });
      });
  };

  openShiftingModal = (details) => {
    console.log({ details }, 'openShiftingModal');
    this.setState({
      showShiftingModal: true,
      shiftingDetails: details,
    });
  };

  openApiBookingModal = (details) => {
    console.log({ details }, 'openApiBookingModal');
    this.setState({
      showApiBookingModal: true,
      shiftingDetails: details,
    });
  };

  openFollowUpModal = (details) => {
    console.log({ details }, 'open follow up modal');
    this.setState({
      showFollowUpModal: true,
      followUpDetails: details,
    });
  };

  openEscallationModal = (details) => {
    console.log({ details }, 'open escallation modal');
    this.setState({
      showEscallationModal: true,
      escallationRequestId: details.requestId,
    });
  };

  closeEscallationModal = () => {
    this.setState({
      showEscallationModal: false,
      followUpDetails: null,
    });
  };

  closeFollowUpModal = () => {
    this.setState({
      showFollowUpModal: false,
      followUpDetails: null,
    });
  };

  openAddDoctorDetailModal = (details) => {
    console.log({ details }, 'open add Doctor Details modal');
    this.setState({
      addDoctorDetailModalOpen: true,
    });
  };

  closeAddDoctorDetailModal = () => {
    this.setState({
      addDoctorDetailModalOpen: false,
    });
  };

  openFetchFollowUpModal = (details) => {
    console.log({ details }, 'open fetch follow up modal');
    this.setState({
      showFetchFollowUpModal: true,
      fetchFollowUpDetails: details,
    });
  };

  closeFetchFollowUpModal = () => {
    console.log('close fetch follow up modal');
    this.setState({
      showFetchFollowUpModal: false,
      fetchFollowUpDetails: null,
    });
  };

  setTotalAndActiveFollowUps = (totalFollowUps, activeFollowUps) => {
    console.log(`lifting state to set total and active followUps`);
    this.setState({
      activeFollowUps: activeFollowUps,
      totalFollowUps: totalFollowUps,
    });
  };

  closeSuggestions = () => {
    this.setState({
      showSuggestions: false,
      suggestionsDetails: null,
    });
  };

  closeShiftingModal = () => {
    this.setState({
      showShiftingModal: false,
      ShiftingDetails: null,
    });
  };

  closeApiBookingModal = () => {
    this.setState({
      showApiBookingModal: false,
      ShiftingDetails: null,
    });
  };

  handleAssigneModal = () => {
    this.setState({
      addAssigneModalOpen: false,
    });
  };

  onSearchClick = () => {
    const { search, loading } = this.state;
    let notMobile = 0;
    let notEmail = 0;
    if (!mobileNumberValidationRegex.test(search)) {
      notMobile = 1;
    }
    if (!emailValidationRegex.test(search)) {
      notEmail = 1;
    }

    if (notEmail && notMobile) {
      toast.error(`Please enter a valid phone number/email`);
      return;
    }

    if (loading) {
      return;
    }
    this.debounceSearch(search);
  };

  render() {
    const {
      loading,
      data,
      pages,
      confirmModal,
      totalHits,
      tableColumns,
      segregation,
      onlyVipUsers,
      delayedResponse,
    } = this.state;

    const params = {
      draw: 0,
      page: this.state.page,
      orderBy: this.state.orderBy,
      orderDir: this.state.orderDir,
      statusFilter: this.state.statusFilter,
      search: this.state.search || null,
      sponsorFilter: this.state.sponsorFilter,
      appointmentPreferenceFilter: this.state.appointmentPreferenceFilter,
      claimedByFilter: this.state.claimedByFilter,
      patientNameFilter: this.state.patientNameFilter,
      doctorNameFilter: this.state.doctorNameFilter,
      networkCenterNameFilter: this.state.networkCenterNameFilter,
      csv: true,
    };

    if (this.state.segregation !== null) {
      params.segregation = this.state.segregation;
    }

    if (this.state.onlyVipUsers && this.state.onlyVipUsers === true) {
      params.onlyVipUsers = true;
    }

    if (this.state.delayedResponse && this.state.delayedResponse === true) {
      params.delayedResponse = true;
    }

    if (
      this.state.requestDateFilter.startDate &&
      this.state.requestDateFilter.endDate
    ) {
      params.requestDateFilterStartDate = moment(
        this.state.requestDateFilter.startDate
      ).format('YYYY-MM-DD');
      params.requestDateFilterEndDate = moment(
        this.state.requestDateFilter.endDate
      ).format('YYYY-MM-DD');
    }

    if (
      this.state.processingDateFilter.startDate &&
      this.state.processingDateFilter.endDate
    ) {
      params.processingDateFilterStartDate = moment(
        this.state.processingDateFilter.startDate
      ).format('YYYY-MM-DD');
      params.processingDateFilterEndDate = moment(
        this.state.processingDateFilter.endDate
      ).format('YYYY-MM-DD');
    }

    if (
      this.state.appointmentDateFilter.startDate &&
      this.state.appointmentDateFilter.endDate
    ) {
      params.appointmentDateFilterStartDate = moment(
        this.state.appointmentDateFilter.startDate
      ).format('YYYY-MM-DD');
      params.appointmentDateFilterEndDate = moment(
        this.state.appointmentDateFilter.endDate
      ).format('YYYY-MM-DD');
    }

    if (this.state.requestIdFilter) {
      params.requestId = this.state.requestIdFilter;
    }

    if (this.state.networkCenterNameFilter) {
      params.networkCenterName = this.state.networkCenterNameFilter;
    }

    if (this.state.doctorNameFilter) {
      params.doctorNameFilter = this.state.doctorNameFilter;
    }

    if (this.state.patientNameFilter) {
      params.patientNameFilter = this.state.patientNameFilter;
    }

    if (this.state.ackFilter) {
      params.acknowledgementCallFilter = this.state.ackFilter;
    }

    if (this.state.inHouseFilter) {
      params.inHouseFilter = this.state.inHouseFilter;
    }

    if (this.state.payoutFilter) {
      params.payoutFilter = this.state.payoutFilter;
    }

    if (this.state.pendingFollowUpFilter) {
      params.pendingFollowUpFilter = this.state.pendingFollowUpFilter;
    }

    if (this.state.isRescheduledFilter) {
      params.isRescheduledFilter = this.state.isRescheduledFilter;
    }

    if (this.state.isReconfirmationRequiredFilter) {
      params.isReconfirmationRequiredFilter =
        this.state.isReconfirmationRequiredFilter;
    }

    if (this.state.isReconfirmationFilter) {
      params.isReconfirmationFilter = this.state.isReconfirmationFilter;
    }

    if (this.state.isPendingCallbackFilter) {
      params.isPendingCallbackFilter = this.state.isPendingCallbackFilter;
    }

    if(this.state.providerClaims) {
      params.statusFilter = 'provider_claims_settlement'
    }

    params.onboardingStatusFilter = this.state.onboardingStatusFilter;
    params.networkSourceFilter = this.state.networkSourceFilter;
    params.paymentCycleFilter = this.state.paymentCycleFilter;
    // params.toDownload = 1;

    const {
      showRequestDetails,
      showInternalComments,
      requestId,
      showEditDoctorSlots,
      doctorId,
    } = this.props.offlineConsult;

    const getDoctorName = () => {
      const { requestDataForConfirm, shiftingDetails, currentRequestDetails } =
        this.state;
      return (
        requestDataForConfirm.doctorName ||
        shiftingDetails.doctorName ||
        currentRequestDetails.doctorName
      );
    };
    const searchLink = Link.state(this, 'search');
    return (
      <OuterComponent>
        <NavBar />
        <PaddedContainer className="pt-4 mt-5">
          <Row>
            <div className="col-1">
              <PlaceRequest
                submit={this.handlePlaceRequestSubmit}
                onComplete={this.onAction}
                providerClaims={this.state.providerClaims}
              />
            </div>
            <div className="col">
              <div className="row">
                <div className="col-8">
                  <SearchBox>
                    <SerachBoxInput
                      valueLink={searchLink}
                      type="text"
                      placeholder="Search with phone/email..."
                      onKeyDown={(e) =>
                        e.key === 'Enter' ? this.onSearchClick() : null
                      }
                    />
                    <SearchBoxContent>{totalHits} records</SearchBoxContent>
                  </SearchBox>
                </div>
                <div className="col-1">
                  {this.state.loading ? (
                    <LoadingComponent />
                  ) : (
                    <button
                      onClick={this.onSearchClick}
                      className="btn btn-primary"
                    >
                      <FaSearch />
                    </button>
                  )}
                </div>
                <div className="col-3"></div>
              </div>
            </div>

            {!this.state.providerClaims ? <div className="col-6">
              <ButtonGroup size={'sm'}>
                <Button
                  style={{ height: '36px' }}
                  onClick={() => this.setSegregationFilter(null)}
                  active={segregation === null}
                >
                  All
                </Button>
                <Button
                  style={{ height: '36px' }}
                  onClick={() => this.setSegregationFilter(0)}
                  active={segregation === 0}
                >
                  Pending
                </Button>
                <Button
                  style={{ height: '36px' }}
                  onClick={() => this.setSegregationFilter(7)}
                  active={segregation === 7}
                >
                  Processing
                </Button>
                <Button
                  style={{ height: '36px' }}
                  onClick={() => this.setSegregationFilter(1)}
                  active={segregation === 1}
                >
                  Confirmed
                </Button>
                <Button
                  style={{ height: '36px' }}
                  onClick={() => this.setSegregationFilter(-1)}
                  active={segregation === -1}
                >
                  Cancelled
                </Button>
                <Button
                  style={{ height: '36px' }}
                  onClick={() => this.setSegregationFilter(2)}
                  active={segregation === 2}
                >
                  Reimbursement
                </Button>

                {/*<Button onClick={() => (this.setSegregationFilter('todaysConfirmed'))} active={segregation === 'todaysConfirmed'}>Today's Confirmed</Button>*/}
              </ButtonGroup>

              <ButtonGroup
                size={'sm'}
                style={{ 'margin-top': '0.4%', 'margin-left': '2rem' }}
              >
                <Button
                  style={{ height: '36px' }}
                  onClick={() => this.setVipUsersFilter()}
                  active={onlyVipUsers === true}
                >
                  Only VIP Users
                </Button>
              </ButtonGroup>

              <ButtonGroup
                size={'sm'}
                style={{ 'margin-top': '0.4%', 'margin-left': '2rem' }}
              >
                <Button
                  style={{ height: '36px' }}
                  onClick={() => this.openAddDoctorDetailModal()}
                >
                  Add Doctor
                </Button>
              </ButtonGroup>

              <ButtonGroup size={'sm'} style={{ 'margin-top': '0.4%' }}>
                <Button
                  style={{
                    height: '50px',
                    width: '96px',
                    'margin-left': '0.4%',
                  }}
                  onClick={() => {
                    this.setSegregationFilter(3);
                  }}
                  active={segregation === 3}
                >
                  Rescheduling Req
                </Button>
                <Button
                  style={{ height: '50px', width: '76px' }}
                  onClick={() => this.setSegregationFilter(6)}
                  active={segregation === 6}
                >
                  Pending Callbacks
                </Button>
                <Button
                  style={{ height: '50px', width: '76px' }}
                  onClick={() => this.setSegregationFilter(8)}
                  active={segregation === 8}
                >
                  Emergency Calls
                </Button>

                <Button
                  style={{ height: '50px', width: '76px' }}
                  onClick={() => this.setSegregationFilter(4)}
                  active={segregation === 4}
                >
                  Pending Reconf
                </Button>

                <Button
                  style={{ height: '50px', width: '76px' }}
                  onClick={() => this.setSegregationFilter(5)}
                  active={segregation === 5}
                >
                  Reconf Done
                </Button>

                <Button
                  style={{
                    'margin-left': '0.4%',
                    height: '50px',
                    width: '76px',
                  }}
                  onClick={this.clearFilters}
                >
                  Clear Filters
                </Button>

                <Button
                  style={{
                    'margin-left': '0.4%',
                    height: '50px',
                    width: '76px',
                  }}
                  onClick={this.delayedResponseFilter}
                >
                  Delayed Request
                </Button>

                <Button
                  style={{
                    'margin-left': '0.4%',
                    height: '50px',
                    width: '76px',
                  }}
                  onClick={this.bulkAssigneRequest}
                >
                  Assign Requests
                </Button>
              </ButtonGroup>
            </div> : <div></div>}
            <div className="col-2">
              <ButtonGroup size={'sm'}>
                <DownloadCsv params={params} />
                <Button
                  id="offline-legend"
                  type="button"
                  onClick={this.toggleLegend}
                >
                  Legend
                </Button>
                <Button onClick={() => this.openSuggestions()}>
                  Suggestions
                </Button>
              </ButtonGroup>
            </div>
            <Modal
              isOpen={this.state.legendOpen}
              toggle={this.toggleLegend}
              size="lg"
              className="rtsp-modal"
            >
              <ModalHeader className="bg-primary" toggle={this.toggleLegend}>
                {'Offline Request Information'}
              </ModalHeader>
              <ModalBody>
                <LegendContent />
              </ModalBody>
            </Modal>
          </Row>
        </PaddedContainer>

        <FoldableTable
          filterable
          data={data}
          columns={tableColumns}
          loading={loading}
          pages={pages}
          manual
          getTrProps={this.getTrProps}
          onFetchData={this.onFetchData}
          showPaginationTop={true}
          className="-striped -highlight"
          SubComponent={(row) => (
            <SubComponent
              requestId={row.original.requestId}
              userId={row.original.userId}
            />
          )}
          pageSizeOptions={[5, 10, 20, 25, 50, 100, 200, 1000, 10000, 100000]}
          defaultSorted={[
            {
              id: 'appointmentDate',
              desc: true,
            },
            // {
            //   id: "requestId",
            //   desc: 'true',
            // }
          ]}
        />

        {/*  Request Reconfirmation*/}
        <Modal
          isOpen={this.state.requestReconfirmationModalOpen}
          toggle={this.toggleRequestReconfirmationModal}
        >
          <ModalHeader
            className="bg-primary"
            toggle={this.toggleRequestReconfirmationModal}
          >
            {`Request Reconfirmation (Request Id: ${this.state.requestReconfirmationRequestId})`}
          </ModalHeader>
          <ModalBody>
            {this.state.loading ? (
              <LoadingComponent />
            ) : (
              <Button
                type="submit"
                size="sm"
                onClick={() =>
                  this.requestReconfirmationForConsultation(
                    this.state.requestReconfirmationRequestId
                  )
                }
              >
                Confirm
              </Button>
            )}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showRequestDetails}
          toggle={this.closeDetailModal}
          size="lg"
          className="rtsp-modal-big"
        >
          <ModalHeader
            className="bg-primary"
            toggle={this.closeDetailModal}
          >{`Request Details (${requestId})`}</ModalHeader>
          <ModalBody>
            <RequestDetails showEditDoctorSlotsModal={this.editDoctorSlots} />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={showEditDoctorSlots}
          toggle={this.closeDoctorSlotsEditModal}
          size="lg"
          className="rtsp-modal-big"
        >
          <ModalHeader
            className="bg-primary"
            toggle={this.closeDoctorSlotsEditModal}
          >{`Doctor Slots Edit (${getDoctorName()}-${doctorId})`}</ModalHeader>
          <ModalBody>
            <EditDoctorSlots />
          </ModalBody>
        </Modal>

        <OfflineModalConfirm
          modalIsOpen={this.state.confirmModalIsOpen}
          openModal={this.confirmOpenModal}
          requestDataForConfirm={this.state.requestDataForConfirm}
          closeModal={this.confirmCloseModal}
          submitModal={this.submitConfirmModal}
          onComplete={this.onAction}
          showEditDoctorSlotsModal={this.editDoctorSlots}
          showEditDoctorSlots={showEditDoctorSlots}
          doctorId={doctorId}
          requestId={requestId}
        />
        <OfflineModalCancel
          modalIsOpen={this.state.cancelModalIsOpen}
          openModal={this.cancelOpenModal}
          closeModal={this.cancelCloseModal}
          submitModal={this.submitCancelModal}
          onComplete={this.onAction}
          requestId={this.state.requestId}
          cancelledReasons={this.state.cancelledReasons}
        />
         <OfflineModalNonPayable
          modalIsOpen={this.state.nonPayableClaimsOpenModal}
          openModal={this.cancelOpenModal}
          closeModal={this.cancelNonPayableCloseModal}
          submitModal={this.submitProviderClaimsModal}
          onComplete={this.onAction}
          requestId={this.state.requestId}
          nonPayableClaimsReasons={this.state.nonPayableClaimsReasons}
        />
         <OfflineModalPayable
          modalIsOpen={this.state.payableClaimsOpenModal}
          openModal={this.cancelOpenModal}
          closeModal={this.cancelPayableCloseModal}
          submitModal={this.submitProviderClaimsModal}
          onComplete={this.onAction}
          requestId={this.state.requestId}
          payableClaimsReasons={this.state.payableClaimsReasons}
        />
        <EditUserPaymentModal
          modalIsOpen={this.state.showUserPaymentModal}
          closeModal={this.closeEditUserPayment}
          onComplete={this.onAction}
          requestId={this.state.showUserPaymentModalRequestId}
        />
        <Modal
          isOpen={showInternalComments}
          toggle={this.closeRequestChatModal}
        >
          <ModalHeader
            className="bg-primary"
            toggle={this.toggleRequestChatModal}
          >
            {`Internal Comments (Request Id: ${requestId})`}
          </ModalHeader>
          <ModalBody>
            <RequestChatModal
              comments={this.state.comments}
              requestId={this.state.requestChat.requestId}
              closeModal={() => this.setState({ requestChatModal: false })}
            />
          </ModalBody>
        </Modal>
        {/*  Shift To Pending Modal*/}
        <Modal
          isOpen={this.state.shiftToPendingModalOpen}
          toggle={this.toggleShiftToPendingModal}
        >
          <ModalHeader
            className="bg-primary"
            toggle={this.toggleShiftToPendingModal}
          >
            {`Shift to pending (Request Id: ${this.state.shiftToPendingRequestId})`}
          </ModalHeader>
          <ModalBody>
            {this.state.loading ? (
              <LoadingComponent />
            ) : (
              <Button
                type="submit"
                size="sm"
                onClick={() =>
                  this.shiftAppointmentToPending(
                    this.state.shiftToPendingRequestId
                  )
                }
              >
                Confirm
              </Button>
            )}
          </ModalBody>
        </Modal>

        {/*  Price Change Request Modal*/}
        <Modal
          isOpen={this.state.priceChangeRequetsModal}
          toggle={this.togglePriceChangeRequest}
        >
          <ModalHeader
            className="bg-primary"
            toggle={this.togglePriceChangeRequest}
          >
            {`Price Change Request`}
          </ModalHeader>
          <ModalBody>
            {this.state.requestIdDataForPriceChange && (
              <>
                <div className="my-1">
                  Doctor Id : {this.state.requestIdDataForPriceChange.doctorId}
                </div>
                <div className="my-1">
                  Doctor Name :{' '}
                  {this.state.requestIdDataForPriceChange.doctorName}
                </div>
                <div className="my-1">
                  Center Id :{' '}
                  {this.state.requestIdDataForPriceChange.networkCenterId}
                </div>
                <div className="my-1">
                  Center Name :{' '}
                  {this.state.requestIdDataForPriceChange.networkCenterName}
                </div>
                <div className="my-1">
                  Previous Price :{' '}
                  {this.state.requestIdDataForPriceChange.lastPrice
                    ? this.state.requestIdDataForPriceChange.lastPrice
                    : 'Not Available'}
                </div>
                <div className="my-1">
                  Last Updated :{' '}
                  {this.state.requestIdDataForPriceChange.priceLastUpatedDate
                    ? new Date(
                        this.state.requestIdDataForPriceChange.priceLastUpatedDate
                      ).toLocaleDateString()
                    : 'Not Available'}
                </div>
              </>
            )}
            <InputBox
              type={'number'}
              value={this.state.newPrice}
              placeholder="Please enter new Prices for offline consultations"
              className="my-3"
              onChange={(e) => this.setState({ newPrice: e.target.value })}
            />
            <AntdSelect
              style={{ width: '100%' }}
              defaultValue="Select Reason"
              onChange={(e) => {
                this.setState({
                  priceChangeReason: e,
                });
              }}
              className="mb-3"
            >
              <Option value="Fee got revised">Fee got revised</Option>
              <Option value="Incorrect fee was updated in our system">
                Incorrect fee was updated in our system
              </Option>
              <Option value="Incorrect price was informed by the centre while booking the appointment">
                Incorrect price was informed by the centre while booking the
                appointment
              </Option>
              <Option value="Other">Other</Option>
            </AntdSelect>
            {this.state.priceChangeReason === 'Other' && (
              <InputBox
                type={'text'}
                value={this.state.inputReason}
                placeholder="Please enter reason for Price Change"
                className="mb-3"
                onChange={(e) => this.setState({ inputReason: e.target.value })}
              />
            )}
            {this.state.loading ? (
              <LoadingComponent />
            ) : (
              <>
                <Button
                  type="submit"
                  size="sm"
                  className="mr-2"
                  style={{ backgroundColor: '#007bff' }}
                  onClick={() => this.sendPriceChangeRequest()}
                  disabled={
                    !this.state.newPrice ||
                    !this.state.priceChangeReason ||
                    (this.state.priceChangeReason === 'Other' &&
                      !this.state.inputReason)
                  }
                >
                  Send for Approval
                </Button>

                <Button
                  type="submit"
                  size="sm"
                  onClick={() =>
                    this.setState({
                      priceChangeRequetsModal: false,
                      newPrice: '',
                      priceChangeReason: '',
                      inputReason: '',
                    })
                  }
                >
                  Cancel
                </Button>
              </>
            )}
          </ModalBody>
        </Modal>
        {/*Doctor Selection for center request */}
        <Modal
          isOpen={this.state.doctorSelectionModal}
          toggle={this.openDoctorSelectionModal}
        >
          <ModalHeader
            className="bg-primary"
            toggle={this.openDoctorSelectionModal}
          >
            Select Doctor
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Search Doctor:</Label>
                </Col>
                <Col>
                  {/* <Async
                      loadOptions={this.getDoctorSelectionList}
                      ref={this.doctorSelectRef}
                      //onChange={(option) => this.getDoctorSelectionList(option)}
                    /> */}
                  <AsyncSelect
                    defaultOptions={this.state.defaultDoctorSearch}
                    loadOptions={this.getDoctorSelectionList}
                    ref={this.doctorSelectRef}
                    //onChange={(option) => this.getDoctorSelectionList(option)}
                  />
                </Col>
              </Row>
            </FormGroup>
            <Button onClick={this.confirmDoctorSelection}>
              Confirm doctor
            </Button>
          </ModalBody>
        </Modal>
        {/*  Shift To Reimbursement Modal*/}

        <Modal
          isOpen={this.state.shiftReimbursementModalOpen}
          toggle={this.toggleShiftToReimbursementModal}
        >
          <ModalHeader
            className="bg-primary"
            toggle={this.toggleShiftToReimbursementModal}
          >
            {`Shift to Reimbursement (Request Id: ${this.state.shiftToReimbursementReqId})`}
          </ModalHeader>
          <ModalBody>
            {this.state.loading ? (
              <LoadingComponent />
            ) : (
              <Button
                type="submit"
                size="sm"
                onClick={() =>
                  this.shiftAppointmentToReimbursement(
                    this.state.shiftToReimbursementReqId
                  )
                }
              >
                Confirm
              </Button>
            )}
          </ModalBody>
        </Modal>

        <ConfirmModal
          modalHeader={confirmModal.header}
          onClose={confirmModal.onClose}
          isOpen={confirmModal.isOpen}
          onSubmit={this.submitConfirmModalGen}
          loading={confirmModal.buttonLoading}
          isCallModalOpen={this.state.isCallModalOpen}
          callReasonOptions={this.state.callReasonOptions}
          handleChange={this.handleChangeForCallReason}
          selectedValue={this.state.selectedCallReason}
        >
          {confirmModal.body}
        </ConfirmModal>
        <ClaimRequestModal
          onClose={this.onClaimRequestModalClose}
          isOpen={this.state.showClaimRequestModal}
          requestId={this.state.showClaimRequestModalRequestId}
        />
        {this.state.openComms && (
          <CommunicationsSetup
            closeModal={this.closeCommunications}
            isOpen={this.state.openComms}
            commsData={this.state.commsData}
          />
        )}

        <SuggestionsDialog
          suggestionsDetails={this.state.suggestionsDetails}
          isOpen={this.state.showSuggestions}
          closeSuggestions={this.closeSuggestions}
        />

        {this.state.showShiftingModal && (
          <RescheduleAppointMentRequest
            isOpen={this.state.showShiftingModal}
            closeShiftingModal={this.closeShiftingModal}
            fetchTableData={this.fetchTableData}
            shiftingDetails={this.state.shiftingDetails}
            showEditDoctorSlotsModal={this.editDoctorSlots}
            showEditDoctorSlots={showEditDoctorSlots}
          />
        )}
        
        {this.state.showApiBookingModal && (
          <OfflineApiBookingModal
            isOpen={this.state.showApiBookingModal}
            closeShiftingModal={this.closeApiBookingModal}
            fetchTableData={this.fetchTableData}
            requestDetails={this.state.shiftingDetails}
          />
        )}

        {this.state.showFollowUpModal && (
          <FollowUpModal
            isOpen={this.state.showFollowUpModal}
            closeFollowUpModal={this.closeFollowUpModal}
            fetchTableData={this.fetchTableData}
            onComplete={this.onAction}
            followUpDetails={this.state.followUpDetails}
          />
        )}

        {this.state.showEscallationModal && (
          <MarkEscallationModal
            isOpen={this.state.showEscallationModal}
            closeEscallationModal={this.closeEscallationModal}
            fetchTableData={this.fetchTableData}
            onComplete={this.onAction}
            requestId={this.state.escallationRequestId}
            //followUpDetails={this.state.followUpDetails}
          />
        )}

        {this.state.showFetchFollowUpModal && (
          <FetchFollowUpModal
            isOpen={this.state.showFetchFollowUpModal}
            closeFetchFollowUpModal={this.closeFetchFollowUpModal}
            fetchFollowUpDetails={this.state.fetchFollowUpDetails}
            onComplete={this.onAction}
          />
        )}

        {this.state.addDoctorDetailModalOpen && (
          <AddDoctorDetailModal
            isOpen={this.state.addDoctorDetailModalOpen}
            closeAddDoctorDetailModal={this.closeAddDoctorDetailModal}
            fetchTableData={this.fetchTableData}
            onComplete={this.onAction}
            followUpDetails={this.state.followUpDetails}
          />
        )}

        {this.state.addAssigneModalOpen && (
          <AddAssigneModal
            isModalOpen={this.state.addAssigneModalOpen}
            closeAssigneModal={this.handleAssigneModal}
            requestId={this.state.addAssigneReqId}
            allCheckedReqIds={this.state.allChecked}
            fetchTableData={this.fetchTableData}
            addAssigneFlag={this.state.addAssigneFlag}
          />
        )}

        <ChangeProcessingDateModal
          requestId={this.state.changeProcessingDateModal.requestId}
          processingDate={this.state.changeProcessingDateModal.processingDate}
          isOpen={this.state.changeProcessingDateModal.isOpen}
          closeProcessingDateModal={this.closeProcessingDateModal}
        />
      </OuterComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  offlineConsult: state.offlineConsult,
});

export default connect(mapStateToProps)(loginCheck(OfflineConsultation));
