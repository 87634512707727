import React, { useEffect, useState, useCallback } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Row,
  Col,
  Label,
  Alert,
} from 'reactstrap';
import { TimePicker } from 'antd';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { BoldText, RegularText } from '../common/Text';

import {
  bookApiAppointment,
  getDoctorSlots as getDocSlots,
} from '../../services/api/offline-consult';

import moment from 'moment';
import LoadingComponent from '../common/LoadingComponent';

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;
`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;
const Warning = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
  padding-top: 1rem;
  color: #ffaf3c;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled.div`
  font-size: 1rem;
  width: ${(props) => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;

const DoctorSlotBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const DetailText = styled(RegularText)`
  flex: 1;
  font-size: 1rem;
  color: ${(props) => props.color || '#787778'};
  margin-left: 0.25rem;
`;

const OfflineApiBookingModal = (props) => {
  const { requestDetails, isOpen, closeShiftingModal, fetchTableData } = props;
  console.log(props);
  const [dateKey, setDateKey] = useState(requestDetails.uAppointmentDate ? moment(requestDetails.uAppointmentDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null);
  const [appointmentStartTime, setAppointmentStartTime] = useState(null);
  const [appointmentEndTime, setAppointmentEndTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [doctorSlots, setDoctorSlots] = useState(null);
  const [doctorNewSlot, setDoctorNewSlot] = useState({
    modal: false,
    data: [],
    date: '',
  });

  const checkWhetherApptDateWithinSlots = () => {
    let response = { isWithinSlots: false };
    if(doctorSlots) {
    const correctSlots = doctorSlots.filter(
      (slot) => slot.formattedDateKey === dateKey
    );
    console.log('checkWhetherApptDateWithinSlots', { correctSlots, appointmentStartTime, appointmentEndTime });

    if (correctSlots[0] && correctSlots[0].slots) {
      for (let slotValue of correctSlots[0].slots) {
        const startTime = moment(slotValue.slotKey, 'hh:mm A').format(
          'hh:mm A'
        );
        const endTime = moment(slotValue.slotKeyEndTime, 'hh:mm A').format(
          'hh:mm A'
        );
        if (
          appointmentStartTime == startTime &&
          appointmentEndTime == endTime
        ) {
          response.isWithinSlots = true;
          if (slotValue.externalSlotInfo && slotValue.externalSlotInfo.slotId) {
            response.slotInfo = {
              slotId: slotValue.externalSlotInfo.slotId,
              token: slotValue.externalSlotInfo.token || null,
            } ;
          }
        }
      }
    }}
    return response;
  };

  const getDoctorSlots = useCallback(() => {
    let { requestId } = requestDetails;

    getDocSlots({ requestId, externalSlotAllow: true })
      .then((res) => {
        console.log(
          { slots: res.data, showSlot: res.data.showSlot },
          'getDoctorSlots'
        );
        setLoading(true);
        setDoctorSlots(res.data.slots);
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => setLoading(false));
  }, [requestDetails]);

  useEffect(() => {
    getDoctorSlots();
  }, []);

  const handleDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    setDateKey(dateKey);
  };

  const bookAppointment = () => {
    setLoading(true);
    const { requestId, centerKey } = requestDetails;
    try {
      if (!dateKey || !appointmentStartTime || !appointmentEndTime) {
        toast.error('Incorrect Selection');
        return;
      }
      const slotCheckResult = checkWhetherApptDateWithinSlots();
      if (!(slotCheckResult && slotCheckResult.isWithinSlots)) {
        toast.error(
          'The selected Appointment Date/Time is not within the Available Doctor Slots. Please select different date/time'
        );
        setLoading(false);
        return;
      }

      return bookApiAppointment({
        requestId,
        externalSlotInfo: {
          ...slotCheckResult.slotInfo,
          slotDate: dateKey,
          slotStartTime: appointmentStartTime,
          slotEndTime: appointmentEndTime,
        },
        centerKey,
      })
        .then((res) => {
          if (res.message === 'success') {
            closeShiftingModal();
            toast.success(`Success`);
            fetchTableData();
            setLoading(false);
          } else {
            const err = new Error('Invalid response');
            err.data = res;
            throw err;
          }
        })
        .catch((err) => {
          console.log({ err, response: err.data });
          setLoading(false);
          toast.error(`Failure! ${err.data.errorMessage || ''}`);
        });
    } catch (e) {
      toast.error(`Failure!`);
      console.log({ e });
      setLoading(false);
    }
  };

  console.log('sgasgabnk', requestDetails);

  return (
    <Modal
      //   onOpened={this.onModalOpened}
      centered
      isOpen={isOpen}
      onCancel={closeShiftingModal}
      toggle={closeShiftingModal}
    >
      <ModalHeader className="revamp-modal-header" toggle={closeShiftingModal}>
        Booking API Appointment - {requestDetails.requestId}
      </ModalHeader>
      <ModalBody>
        <form>
          <FormGroup>
            <Warning>
              {
                'This appointment booking is API driven and it got failed due to some reason. Here, We can book the appointment again in particular center system.'
              }
            </Warning>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col
                className="col-3"
                style={{
                  borderRadius: '15px',
                }}
              >
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    width: 'max-content',
                  }}
                >
                  Appointment Date:
                </Label>
              </Col>
              <Col>
                <input
                  className="form-control"
                  type="date"
                  value={dateKey}
                  onChange={handleDateChange}
                  style={{ borderRadius: '15px' }}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col style={{ borderRadius: '15px' }} className="col-3">
                <Label
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: '500',
                    width: 'max-content',
                  }}
                >
                  Appointment Time:
                </Label>
              </Col>
              <Col className="col-4">
                <TimePicker
                  className="w-full"
                  onChange={(_, date) => {
                    console.log(date);
                    const newTime =
                      date.substring(0, 3) +
                      date.substring(3, 5) +
                      ' ' +
                      date.substring(9);
                    setAppointmentStartTime(newTime);
                  }}
                  placeholder="Start Time"
                  format="hh:mm:00 A"
                  defaultValue={ appointmentStartTime }
                />
              </Col>
              <Col className="col-4">
                <TimePicker
                  className="w-full"
                  onChange={(_, date) => {
                    const newTime =
                      date.substring(0, 3) +
                      date.substring(3, 5) +
                      ' ' +
                      date.substring(9);
                    setAppointmentEndTime(newTime);
                  }}
                  format="hh:mm:00 A"
                  placeholder="End Time"
                  defaultValue={ appointmentEndTime }
                />
              </Col>
            </Row>
          </FormGroup>
        </form>
        <SectionInnerContainer>
          <SectionHeader>Doctor Slots</SectionHeader>
          <SectionBody>
            {!loading && doctorSlots &&
              doctorSlots.map((slot) => (
                <DetailRow key={slot.dateKey}>
                  <DetailName>{`${slot.formattedDateKey}:`}</DetailName>
                  <DetailText>
                    {slot && slot.slots && slot.slots.slice(0, 4)
                      .map((value) => `${value.timings}`)
                      .join(', ')}{' '}
                    {slot && slot.slots && slot.slots.length > 4 && (
                      <span
                        style={{
                          color: 'green',
                          cursor: 'pointer',
                          fontWeight: 700,
                        }}
                        onClick={() =>
                          setDoctorNewSlot({
                            modal: true,
                            data: slot.slots,
                            date: slot.formattedDateKey,
                          })
                        }
                      >
                        ...more
                      </span>
                    )}
                  </DetailText>
                </DetailRow>
              ))}
          </SectionBody>
        </SectionInnerContainer>
        <Modal
          isOpen={doctorNewSlot.modal}
          onCancel={() => setDoctorNewSlot({ modal: false, data: [] })}
        >
          <ModalHeader
            className="bg-primary"
            toggle={() => setDoctorNewSlot({ modal: false, data: [] })}
          >
            Doctor Slots
          </ModalHeader>
          <ModalBody>
            <DetailRow>
              <DetailName>{`${doctorNewSlot.date}:`}</DetailName>
              <DoctorSlotBox>
                {doctorNewSlot.data.map((value) => (
                  <p
                    style={{
                      padding: '6px',
                      backgroundColor: 'skyblue',
                      borderRadius: '8px',
                      margin: '4px',
                    }}
                  >
                    {value.timings}
                  </p>
                ))}
              </DoctorSlotBox>
            </DetailRow>
          </ModalBody>
        </Modal>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        {loading ? <LoadingComponent color={'black'} /> : <></>}
        <Button
          style={{ backgroundColor: '#714FFF', width: '40%' }}
          disabled={loading || !dateKey}
          onClick={() => bookAppointment()}
        >
          Book Appointment
        </Button>
        <Button
          style={{
            width: '40%',
            backgroundColor: '#FFFFFF',
            color: '#714FFF',
            border: '1px solid #714FFF',
          }}
          onClick={() => closeShiftingModal()}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OfflineApiBookingModal;
