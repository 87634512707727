import React from 'react';
import {BoldText, RegularText} from "../common/Text";
import {Button, Input} from "reactstrap";
import StyledTappable from "../common/StyledTappable";
import styled from "styled-components";
import {changeDoctorActivity, removeDoctor, updatePriority} from "../../services/api/pool-manager";
import {toast} from "react-toastify";

import RosterComponent from './RosterComponent';
import LoadingComponent from "../common/LoadingComponent";

import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const OuterComponent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const OuterCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  box-shadow: 2px 3px 3px 2px rgba(0, 0, 0, 0.1);
  /* border: 1px solid #d5d5d5; */
  border-radius: 0.5rem;
  justify-content: space-between;
`;


const ActionComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const DetailsComponent = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;


class Specialist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingActivity: false,
            isRosterVisible: false,
            priority: '',

        };
    }


    handleDoctorActivity = ele => {
        const { selectedPool } = this.props;
        this.setState({
            loadingActivity: true,
        });
        return changeDoctorActivity(selectedPool.poolId, ele.doctorId, !ele.active)
            .then(res => {
                console.log({ res });
                if (res.message === 'success') {
                    return this.props.onComplete();
                } else {
                    toast.error(`Failure! ${res.errorMessage}`);
                }
            })
            .catch(err => {
                console.log({ err });
                toast.error(`Failure! ${err.errorMessage}`);
            })
            .finally(() => {
                this.setState({
                    loadingActivity: false,
                });
            });
    };

    removeDoctorFromPool = ele => {
        const { selectedPool } = this.props;
        this.setState({
            loadingActivity: true,
        });
        return removeDoctor(selectedPool.poolId, ele.doctorId)
            .then(res => {
                if (res.message === 'success') {
                    this.props.onComplete();
                } else {
                    toast.error(`Failure! ${res.errorMessage}`);
                }
            })
            .catch(err => {
                console.log({ err });
                toast.error(`Failure! ${err.errorMessage}`);
            })
            .finally(() => {
                this.setState({
                    loadingActivity: false,
                });
            });
    };

    handleDetailsClick = () => {
        const { isRosterVisible } = this.state;
        this.setState({
            isRosterVisible: !isRosterVisible,
        });
    };

    onPriorityChange = (e) => {
        console.log(e.target.value, 'ssss');
        this.setState({
            priority: e.target.value,
        });
    }

    handlePriorityChange = () => {
        let { doctor, selectedPool, specialists } = this.props;
        const priority = this.state.priority;
        const cPrDoc = doctor.priority;
        let type;
        if (priority < cPrDoc) {
            specialists = specialists.filter((s) => (s.priority >= priority && s.priority < cPrDoc && s.active));
            type = 'add';
        }
        if (priority > cPrDoc) {
            specialists = specialists.filter((s) => (s.priority <= priority && s.priority > cPrDoc && s.active));
            type = 'sub';
        }
        // Update priority of current doctor
        // Update rest of the specialists by ++ or --
        console.log(specialists, '[Priority update for these verticals]')
        this.setState({
            loadingActivity: true,
        });
        return updatePriority(selectedPool.poolId, doctor.doctorId, priority, specialists, type)
            .then(res => {
                if (res.message === 'success') {
                    this.props.onComplete();
                } else {
                    toast.error(`Failure! ${res.errorMessage}`);
                }
            })
            .catch(err => {
                console.log({ err });
                toast.error(`Failure! ${err.errorMessage}`);
            })
            .finally(() => {
                this.setState({
                    loadingActivity: false,
                    priority: ''
                });
            });
    }

    handleMoveUp = () => {
        const { doctor, selectedPool } = this.props;
        this.setState({
            loadingActivity: true,
        });
        return updatePriority(selectedPool.poolId, doctor.doctorId, doctor.priority - 1)
            .then(res => {
                if (res.message === 'success') {
                    this.props.onComplete();
                } else {
                    toast.error(`Failure! ${res.errorMessage}`);
                }
            })
            .catch(err => {
                console.log({ err });
                toast.error(`Failure! ${err.errorMessage}`);
            })
            .finally(() => {
                this.setState({
                    loadingActivity: false,
                });
            });
    };

    handleMoveDown = () => {
        const { doctor, selectedPool } = this.props;
        this.setState({
            loadingActivity: true,
        });
        return updatePriority(selectedPool.poolId, doctor.doctorId, doctor.priority + 1)
            .then(res => {
                if (res.message === 'success') {
                    this.props.onComplete();
                } else {
                    toast.error(`Failure! ${res.errorMessage}`);
                }
            })
            .catch(err => {
                console.log({ err });
                toast.error(`Failure! ${err.errorMessage}`);
            })
            .finally(() => {
                this.setState({
                    loadingActivity: false,
                });
            });
    };

    render() {
        const { doctor, selectedPool } = this.props;
        const { loadingActivity, isRosterVisible } = this.state;
        return (
            <OuterComponent>
                <OuterCard>
                    <DetailsComponent onClick={this.handleDetailsClick}>
                        <BoldText>{`${doctor.first_name} (${doctor.doctorId})`}</BoldText>
                        <RegularText>{doctor.vertical}</RegularText>
                        <RegularText><span style={{fontWeight: 'bold'}}>Visit price: </span>{doctor.visitPrice ? doctor.visitPrice : 'NA'}</RegularText>
                    </DetailsComponent>
                    <ActionComponent>
                        {(loadingActivity) ? (
                            <LoadingComponent />
                        ) : (
                            <React.Fragment>
                                <Input
                                    style={{ marginBottom: 4 }}
                                    type="checkbox"
                                    checked={doctor.active}
                                    onChange={event => this.handleDoctorActivity(doctor)}
                                />
                                <StyledTappable className="mr-2" onTap={() => this.handleDoctorActivity(doctor)}>
                                    {doctor.active ? 'Active' : 'Inactive'}
                                </StyledTappable>
                                {/* <span className="badge badge-secondary">{doctor.priority}</span>
                                <FaArrowUp className="clickable ml-1" title="Move Up" onClick={this.handleMoveUp} />
                                <FaArrowDown className="clickable ml-1" title="Move Down" onClick={this.handleMoveDown} /> */}
                                {/* <input
                                    style={{ marginBottom: 4, width: '50px' }}
                                    type="text"
                                    value={this.state.priority}
                                    onChange={this.onPriorityChange}
                                />
                                <Button
                                    style={{ marginLeft: 15, width: '50px' }}
                                    color="primary"
                                    onClick={this.handlePriorityChange}
                                >
                                    Set
                                </Button> */}
                                <Button
                                    style={{ marginLeft: 15 }}
                                    color="secondary"
                                    onClick={() => this.removeDoctorFromPool(doctor)}
                                >
                                    Remove
                                </Button>
                            </React.Fragment>
                        )}
                    </ActionComponent>
                </OuterCard>
                {(isRosterVisible) ? (
                    <RosterComponent doctorId={doctor.doctorId} poolId={selectedPool.poolId} />
                ) : (
                    <React.Fragment />
                )}
            </OuterComponent>
        );
    }
}

export default Specialist;
